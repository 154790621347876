<template>
  <v-layout>
    <v-flex>
      <h2>Sonstiges</h2>
      <v-checkbox
        :disabled="disabled"
        :label="labels.smallCrossBorderTraffic"
        color="primary"
        v-model="masterData.smallCrossBorderTraffic"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import fieldnames from '@/pages/masterDataView/fieldnames'

export default {
  name: 'MasterdataOther',
  props: {
    masterData: {},
    disabled: Boolean,
  },
  computed: {
    labels() {
      return fieldnames
    },
  },
}
</script>
