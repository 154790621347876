<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        :value="textFieldValue"
        box
        readonly
        v-on="on"
      />
    </template>
    <v-date-picker
      :first-day-of-week="1"
      :value="value"
      @input="setDate"
      locale="de-de"
      type="month"
      :allowed-dates="allowedDates"
    />
  </v-menu>
</template>

<script>
import { toMonthYear } from '@/utils/DateHelper'

export default {
  name: 'Monthpicker',
  props: {
    value: String,
    label: String,
    dialogFrom: String,
    dialogTo: String,
    items: Array,
    editedItem: Object,
  },

  data() {
    return {
      menu: false,
    }
  },
  computed: {
    textFieldValue() {
      try {
        return toMonthYear(this.value)
      } catch (e) {
        return ''
      }
    },
    from() {
      return this.dialogFrom ? new Date(this.dialogFrom) : null
    },
    to() {
      return this.dialogTo ? new Date(this.dialogTo) : null
    },
  },
  methods: {
    allowedDates(val) {
      const date = new Date(val)

      if ((this.from && date < this.from) || (this.to && date > this.to))
        return false

      const result = this.items.find((item) => {
        if (item.to && item.from && item !== this.editedItem) {
          const from = new Date(item.from)
          const to = new Date(item.to)

          if (this.from && this.from <= from && date > from) {
            return true
          } else if (this.to && this.to >= to && date < to) {
            return true
          }
          return !(date <= from || date >= to)
        }
      })

      return !result
    },
    setDate(newDate) {
      this.menu = false
      this.$emit('input', newDate)
    },
  },
}
</script>
