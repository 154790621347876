<template>
  <v-autocomplete
    v-model="model"
    clearable
    :search-input.sync="search"
    hint="AGS, Gemeindename, etc. "
    label="Stammdaten Suche"
    persistent-hint
    item-value="id"
    return-object
    no-filter
    v-bind="{ disabled, items, itemText }"
    box
  >
    <template #item="{ item }">
      <v-list-tile-avatar style="flex-basis: 10%">
        <v-avatar :color="item.company.main_color" size="16" />
      </v-list-tile-avatar>
      <v-list-tile-content style="flex-basis: 45%">
        <v-list-tile-title>{{ item.municipality }} </v-list-tile-title>
        <v-list-tile-sub-title
          >{{ item.company.name }} / AGS {{ item.ags }}</v-list-tile-sub-title
        >
      </v-list-tile-content>
      <v-list-tile-content style="flex-basis: 45%">
        <v-list-tile-sub-title v-if="item.parent"
          >(Ortst. von) {{ item.parent.municipality }}</v-list-tile-sub-title
        >
        <v-list-tile-sub-title
          >(VerwGem)
          {{
            item.addressAdministrativeMunicipality.name
          }}</v-list-tile-sub-title
        >
      </v-list-tile-content>
    </template>
    <template #selection="{ item }">
      {{ item.municipality }} ({{ item.ags }})
    </template>
  </v-autocomplete>
</template>

<script>
import masterdataApi from '@/api/masterdataApi'

export default {
  name: 'SearchMasterdataTypeahead',
  props: ['companyId', 'disabled'],
  data() {
    return {
      model: null,
      search: null,
      items: [],
    }
  },

  methods: {
    itemText(item) {
      return `${item.ags} ${item.municipality}`
    },
  },

  watch: {
    model(value) {
      const id = value?.id
      this.$emit('itemSelected', id)
    },
    async search(searchValue) {
      const companyId = this.companyId || null
      if (searchValue && searchValue.length >= 3) {
        const { data } = await masterdataApi.searchEntries(
          companyId,
          searchValue,
          1,
          200
        )
        this.items = data
      }
    },
  },
}
</script>

<style scoped></style>
