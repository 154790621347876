<template>
  <v-dialog max-width="800" v-model="showModal">
    <v-card>
      <v-card-title>
        <h3>Kassenzeichen</h3>
      </v-card-title>
      <v-card-text>
        <v-layout>
          <v-flex>
            <v-text-field
              :placeholder="masterData.referenceNumber"
              label="Neues Kassenzeichen"
              v-model="newReferenceNumber"
            />
          </v-flex>
          <v-btn :disabled="!newReferenceNumber" @click="saveNewReferenceNumber"
            >Speichern</v-btn
          >
        </v-layout>
      </v-card-text>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout column wrap>
            <h3>Bisherige Kassenzeichen</h3>
            <ul>
              <li class="font-weight-bold">
                {{ masterData.referenceNumber }}
              </li>
              <li
                :key="`${item}-${index}`"
                v-for="(item, index) in masterData.oldReferenceNumbers"
              >
                {{ item }}
              </li>
            </ul>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn :loading="saving" @click="showModal = false" color="primary" flat
          >Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import masterdataApi from '@/api/masterdataApi'

export default {
  name: 'ModalEditReferenceNumber',
  props: {
    masterData: {
      type: Object,
      required: true,
    },
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      newReferenceNumber: '',
      saving: false,
    }
  },
  computed: {
    showModal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async saveNewReferenceNumber() {
      if (this.newReferenceNumber) {
        try {
          this.saving = true
          const { referenceNumber, oldReferenceNumbers } =
            await masterdataApi.changeReferenceNumber(
              this.masterData.id,
              this.newReferenceNumber
            )
          this.$root.setSnackbar('success', 'Kassenzeichen wurde aktualisiert')
          Object.assign(this.masterData, {
            referenceNumber,
            oldReferenceNumbers,
          })
          this.newReferenceNumber = ''
        } catch (e) {
          const errorObject = e.errorObject
          if (errorObject) {
            const { referenceNumber } = errorObject
            this.$root.setSnackbar('error', referenceNumber)
          }
        }
        this.saving = false
      }
    },
  },
}
</script>
