<template>
  <v-dialog persistent v-model="isDialog" width="500">
    <v-card class="content">
      <v-card-title primary-title><h3>Eingemeindung</h3></v-card-title>
      <v-card-text>
        <search-masterdata-typeahead
          :company-id="masterData.company_id"
          @itemSelected="(value) => (formData.parent_master_data_id = value)"
          class="mb-4"
        />

        <datepicker
          label="Eingemeindet seit"
          v-model="formData.suburbanisedAt"
        />

        <v-alert
          :value="
            formData.overwriteContact ||
            formData.overwriteAdministrativeMunicipality
          "
          type="error"
        >
          <strong>Achtung!</strong> Überschriebene Daten gehen unwiderruflich
          verloren.
        </v-alert>

        <v-checkbox
          label="Kontaktdaten überschreiben"
          v-model="formData.overwriteContact"
        />

        <v-checkbox
          label="Anschrift überschreiben"
          v-model="formData.overwriteAdministrativeMunicipality"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Abbrechen</v-btn>
        <v-btn :disabled="!valid" @click="save" color="primary"
          >Auswählen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchMasterdataTypeahead from '@/components/SearchMasterdataTypeahead'
import Datepicker from '@/components/dates/Datepicker'
import { isDialog } from '@/mixins/isDialog'

export default {
  name: 'ModalSetAsSubmunicipalityOf',
  components: { Datepicker, SearchMasterdataTypeahead },
  mixins: [isDialog],
  props: ['masterData'],
  data() {
    return {
      formData: {
        parent_master_data_id: null,
        suburbanisedAt: null,
        overwriteContact: false,
        overwriteAdministrativeMunicipality: false,
      },
    }
  },

  computed: {
    valid() {
      return Boolean(
        this.formData.parent_master_data_id && this.formData.suburbanisedAt
      )
    },
  },
  methods: {
    async save() {
      this.$emit('itemSelected', this.formData)
      this.close()
    },
    close() {
      this.isDialog = false
    },
  },
}
</script>

<style scoped></style>
