var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-divider'),_vm._l((_vm.paymentData),function(payment,index){return _c('div',{key:index},[_c('payment-table-line',_vm._b({ref:"paymentLine",refInFor:true,attrs:{"year":_vm.year,"is-protected":_vm.paymentData.length <= _vm.protectedLines},on:{"jumpToNextLine":function($event){return _vm.selectNextLine(index)},"removeLine":function (line) { return _vm.$emit('removeLine', line); },"showDocument":_vm.showDocument,"showNotes":_vm.showNotes,"showDateList":_vm.showDateList},model:{value:(_vm.paymentData[index]),callback:function ($$v) {_vm.$set(_vm.paymentData, index, $$v)},expression:"paymentData[index]"}},'payment-table-line',{
        editable: _vm.editable,
        documentData: _vm.documentData,
        hasTax: _vm.hasTax,
        hasInterest: _vm.hasInterest,
        title: _vm.getTitle(index),
      },false)),_c('v-divider')],1)}),(_vm.showDummyRow)?_c('div',[_c('payment-table-line',_vm._b({attrs:{"year":_vm.year},model:{value:(_vm.newEntry),callback:function ($$v) {_vm.newEntry=$$v},expression:"newEntry"}},'payment-table-line',{
        editable: false,
        documentData: _vm.documentData,
        hasTax: _vm.hasTax,
        hasInterest: _vm.hasInterest,
        canHaveDelete: false,
        canHaveNotes: false,
        canHaveInterestCalculator: false,
      },false)),_c('v-divider')],1):_vm._e(),(_vm.showNewRow)?_c('div',{staticClass:"new-payment-line"},[_c('v-divider'),_c('payment-table-line',_vm._b({key:("newField-" + (_vm.paymentData.length + 1)),ref:"newRow",attrs:{"year":_vm.year},on:{"saveLine":_vm.addNewLine,"showDocument":_vm.showDocument},model:{value:(_vm.newEntry),callback:function ($$v) {_vm.newEntry=$$v},expression:"newEntry"}},'payment-table-line',{
        editable: _vm.editable,
        documentData: _vm.documentData,
        hasTax: _vm.hasTax,
        hasInterest: _vm.hasInterest,
        canHaveDelete: false,
        canHaveNotes: false,
        canHaveInterestCalculator: false,
      },false)),_c('v-divider')],1):_vm._e(),(_vm.notesModal.selectedLine)?_c('AllNotesModal',{attrs:{"id":_vm.notesModal.selectedLine.id,"notes":_vm.notesModal.selectedLine.notes,"type":"paymentLineNotes"},model:{value:(_vm.notesModal.visible),callback:function ($$v) {_vm.$set(_vm.notesModal, "visible", $$v)},expression:"notesModal.visible"}}):_vm._e(),_c('PaymentLineDocumentDatesModal',{attrs:{"payment-line":_vm.dateListModal.paymentLine},model:{value:(_vm.dateListModal.visible),callback:function ($$v) {_vm.$set(_vm.dateListModal, "visible", $$v)},expression:"dateListModal.visible"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }