var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-divider'),_c('div',[_c('payment-table-line',_vm._b({attrs:{"year":_vm.year},model:{value:(_vm.paymentsInterestSum),callback:function ($$v) {_vm.paymentsInterestSum=$$v},expression:"paymentsInterestSum"}},'payment-table-line',{
        editable: false,
        hasTax: false,
        hasInterest: true,
        title: 'Zinssumme',
        canHaveDelete: false,
        canHaveNotes: false,
        canHaveInterestCalculator: false,
      },false)),_c('payment-table-line',_vm._b({attrs:{"year":_vm.year},model:{value:(_vm.paymentsSum),callback:function ($$v) {_vm.paymentsSum=$$v},expression:"paymentsSum"}},'payment-table-line',{
        editable: false,
        hasTax: true,
        hasInterest: true,
        hasInterestAdditional: false,
        hasInterestRefund: false,
        title: 'Summe',
        canHaveDelete: false,
        canHaveNotes: false,
        canHaveInterestCalculator: false,
      },false)),_c('v-divider'),_c('payment-table-row-wrap',{scopedSlots:_vm._u([{key:"col1",fn:function(){return [_vm._v("GewSt. gem. Ber.")]},proxy:true},{key:"col2-1",fn:function(){return [_c('payment-inline-edit-money',{attrs:{"editable":true,"label":"GewSt. gem. Berechnung"},on:{"jumpToNextField":_vm.businessTaxChanged},model:{value:(_vm.selectedPayment.businessTax),callback:function ($$v) {_vm.$set(_vm.selectedPayment, "businessTax", $$v)},expression:"selectedPayment.businessTax"}})]},proxy:true}])}),_c('v-divider'),_c('payment-table-line',_vm._b({attrs:{"year":_vm.year},model:{value:(_vm.paymentsSaldo),callback:function ($$v) {_vm.paymentsSaldo=$$v},expression:"paymentsSaldo"}},'payment-table-line',{
        editable: false,
        hasTax: true,
        hasInterest: false,
        hasInterestRefund: false,
        hasInterestAdditional: false,
        title: 'Saldo',
        canHaveDelete: false,
        canHaveNotes: false,
        canHaveInterestCalculator: false,
      },false)),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }