<template>
  <v-layout :key="parent.id" class="my-3" v-if="parent">
    <v-flex>
      <h2>Übergeordnete Gemeinde</h2>
      <v-card>
        <v-list>
          <v-list-tile
            :to="{
              name: 'MasterDataView',
              params: { id: parent.id },
            }"
          >
            <v-list-tile-title
              >{{ parent.municipality }} — {{ parent.ags }}</v-list-tile-title
            >
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ParentMunicipalityCard',
  props: ['parent'],
}
</script>

<style scoped></style>
