import { diff } from 'deep-object-diff'

const hasDiff = {
  data() {
    return {
      diffOriginalCopy: null,
    }
  },
  methods: {
    setDiffObservable(value) {
      this.diffOriginalCopy = JSON.parse(JSON.stringify(value))
    },
  },

  computed: {
    diff() {
      if (this.diffObserved === undefined) {
        throw new Error('hasDiff mixin needs property diffObserved')
      }
      const result = diff(this.diffOriginalCopy, this.diffObserved)
      if (typeof this.diffFilter === 'function') {
        return this.diffFilter(JSON.parse(JSON.stringify(result)))
      }
      return result
    },

    hasDiff() {
      return this.diff && Object.keys(this.diff).length > 0
    },
  },
}

export { hasDiff }
