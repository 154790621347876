<template>
  <div>
    <datepicker
      :disabled="isLocked"
      :hide-details="true"
      :readonly="isLocked"
      @input="openModal"
      label="Bescheiddatum"
      v-model="documentDate"
    />
    <v-alert :value="!documentDate" type="error"
      >Bitte geben Sie ein Bescheiddatum ein
    </v-alert>

    <ConfirmModal
      :ok-action="save"
      :text="`Wollen Sie wirklich das Bescheiddatum auf ${formatDate(
        documentDate
      )} ändern?`"
      title="Bescheiddatum ändern?"
      v-model="confirmModal"
    />
  </div>
</template>
<script>
import Datepicker from '@/components/dates/Datepicker'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ExtractionViewDocumentDate',
  components: { ConfirmModal, Datepicker },
  props: {
    isLocked: {
      required: true,
      type: Boolean,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documentDate: this.value,
      confirmModal: false,
    }
  },
  methods: {
    openModal() {
      this.confirmModal = true
    },
    formatDate(date) {
      return date.split('-').reverse().join('.')
    },
    async save() {
      this.$emit('changeDocumentDate', this.documentDate)
      return Promise.resolve() // ok-action is promise based, so return a simple resolved promise
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.documentDate = value
      },
    },
  },
}
</script>
