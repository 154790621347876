<template>
  <v-select
    :items="paymentYears"
    v-if="masterData"
    v-model="currentYear"
    :disabled="paymentYears.length === 0"
    hide-details
    box
    label="Bescheidjahr"
  />
</template>
<script>
export default {
  name: 'PaymentYearPicker',
  props: {
    masterData: {
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    paymentYears() {
      if (this.masterData.payments) {
        let result = this.masterData.payments.map((el) => Number(el.year))
        result.sort()
        result.reverse()
        return result.map((year) => String(year))
      }
      return []
    },
    currentYear: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
