<template>
  <payment-table-row-wrap
    @mouseleave.native="hover = false"
    @mouseover.native="hover = true"
  >
    <template #col1>{{ title }}</template>
    <template v-slot:col2-1>
      <payment-inline-edit-money
        ref="input-tax"
        v-if="hasTax"
        :editable="editable && !value.tax.booked"
        v-model="value.tax.value"
        v-on="inputFieldEventBindings('tax')"
      />
    </template>
    <template v-slot:col2-2>
      <payment-inline-edit-money
        v-if="hasInterest"
        :editable="false"
        :value="value.interest.value"
      />
    </template>
    <template v-slot:col2-3>
      <payment-inline-edit-money
        ref="input-interest_additional"
        v-if="hasInterest"
        :editable="editable && !value.interest_additional.booked"
        v-model="value.interest_additional.value"
        v-on="inputFieldEventBindings('interest_additional')"
      />
    </template>
    <template v-slot:col2-4>
      <payment-inline-edit-money
        ref="input-interest_refund"
        v-if="hasInterest"
        :editable="editable && !value.interest_refund.booked"
        v-model="value.interest_refund.value"
        v-on="inputFieldEventBindings('interest_refund')"
      />
    </template>
    <template #col3>
      <div class="col-3">
        <div v-if="lastDateEntry" class="date-section">
          <a
            v-if="lastDateEntry.document_id"
            @click="showDocument"
            class="document-link"
          >
            {{ lastDateEntry.date | formatDate }}</a
          >
          <template v-else>
            {{ lastDateEntry.date | formatDate }}
          </template>
          <a
            @click="$emit('showDateList', value)"
            class="grey--text"
            v-if="value.documentDates.length > 1"
          >
            ({{ value.documentDates.length }})</a
          >
        </div>
        <div class="link-section">
          <v-icon @click="removeMe" small v-if="isRemoveable">delete</v-icon>
          <PaymentInterestCalculator
            v-if="hasInterestCalculator"
            :payment-data="value"
            :payment-year="year"
            :document-data="documentData"
          />
          <v-icon @click="$emit('showNotes', value)" small v-if="hasNotes"
            >message
          </v-icon>
        </div>
      </div>
    </template>
  </payment-table-row-wrap>
</template>

<script>
import { dateTimeToGermanDate } from '@/utils/DateHelper'
import PaymentInlineEditMoney from '@/pages/paymentDataView/paymentDataViewContent/PaymentInlineEditMoney'
import PaymentTableRowWrap from '@/pages/paymentDataView/paymentDataViewContent/PaymentTableRowWrap'
import interestRatesApi from '@/api/interestRatesApi'

import { interpret } from 'xstate'
import formatDate from 'date-fns/format'
import PaymentInterestCalculator from '@/pages/paymentDataView/paymentDataViewContent/paymentTableListing/PaymentInterestCalculator'
import addDays from 'date-fns/add_days'
import inputLineMachine from '@/pages/paymentDataView/paymentDataViewContent/paymentTableListing/paymentTableLineStateMachine'

export default {
  name: 'PaymentTableLine',
  components: {
    PaymentInterestCalculator,
    PaymentTableRowWrap,
    PaymentInlineEditMoney,
  },
  props: {
    year: {
      type: String,
    },
    documentData: {
      type: [Object, Boolean],
    },
    value: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    hasTax: {
      type: Boolean,
      default: true,
    },
    hasInterest: {
      type: Boolean,
      default: true,
    },
    isProtected: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    canHaveDelete: {
      type: Boolean,
      default: true,
    },
    canHaveNotes: {
      type: Boolean,
      default: true,
    },
    canHaveInterestCalculator: {
      tpe: Boolean,
      default: true,
    },
  },
  inject: ['documentDate'],
  data() {
    return {
      isDirty: false,
      hover: false,
    }
  },
  computed: {
    isDocumentOpen() {
      if (this.documentData) {
        return Boolean(this.documentData.document_id)
      }
      return false
    },

    docDates() {
      if (this.value.documentDates) {
        return this.value.documentDates
      }
      return []
    },

    lastDateEntry() {
      return this.docDates[this.docDates.length - 1]
    },

    isCurrentlyOpenDocument() {
      if (this.isDocumentOpen) {
        const dateEntry = this.lastDateEntry
        if (dateEntry) {
          return dateEntry.document_id === this.documentData.document_id
        }
      }
      return false
    },
    isRemoveable() {
      return this.canHaveDelete && !this.value.id
    },
    hasInterestCalculator() {
      return (
        this.canHaveInterestCalculator && this.hasInterest && !this.value.id
      )
    },
    hasNotes() {
      const showNotes =
        (this.value.notes && this.value.notes.length) || this.hover
      const hasId = this.value.id
      return this.canHaveNotes && hasId && showNotes
    },
  },
  methods: {
    send(event, data) {
      this.inputService.send(event, data)
    },
    setDirty() {
      this.isDirty = true
    },
    removeMe() {
      this.$emit('removeLine', this.value)
    },

    fieldChanged() {
      if (!this.documentData) {
        this.$emit('editError', 'documentDate')
        return
      }

      const last = this.lastDateEntry
      const hasNoLast = !last
      const validDocument =
        this.documentData.document_id && this.documentData.date
      const differentThanLast =
        last && last.document_id !== this.documentData.document_id

      if (!validDocument) {
        this.$emit('editError', 'documentDate')
        return
      }

      if (hasNoLast || differentThanLast) {
        this.value.documentDates.push({
          date: this.documentData.date,
          document_id: this.documentData.document_id,
        })
      }
    },

    getInputFieldsInThisLine() {
      const elements = []
      ;['tax', 'interest_additional', 'interest_refund'].forEach((field) => {
        const ref = this.$refs[`input-${field}`]
        if (ref) {
          elements.push({
            field,
            ref,
          })
        }
      })
      return elements
    },

    focusLine() {
      const [firstInLine] = this.getInputFieldsInThisLine()
      firstInLine.ref.focus()
    },

    showDocument() {
      this.$emit('showDocument', this.lastDateEntry.document_id)
    },
    async calcInterest() {
      const paymentYear = this.year
      const periods = this.$store.state.defaultInterestPeriods
      const interestPeriod = periods?.find(
        (el) => String(el.year) === String(paymentYear)
      )

      if (!interestPeriod) {
        this.$root.setSnackbar(
          'error',
          `Für das Jahr ${this.year} wurde keine Zinsperiode festgelegt`
        )
        return
      }

      const amount = this.value.tax.value
      const endDate = formatDate(
        addDays(new Date(this.documentData.date), 3),
        'DD.MM.YYYY'
      )
      const startDate = formatDate(interestPeriod.starts_at, 'DD.MM.YYYY')
      const newValue = {
        ...this.value,
      }
      return interestRatesApi
        .calculateRate(amount, startDate, endDate)
        .then(async ({ result }) => {
          if (result < 0) {
            newValue.interest_refund = { value: result }
          }
          if (result > 0) {
            newValue.interest_additional = { value: result }
          }

          await this.$nextTick()
          return newValue
        })
        .catch((e) => {
          this.$root.setSnackbar('error', 'Zins konnte nicht berechnet werden.')
          return newValue
        })
        .finally(() => {
          this.$emit('input', newValue) // always broadcast that a new value has been set
        })
    },

    inputFieldEventBindings(field) {
      return {
        elementActivated: () => this.send('elementActivated', { field }),
        input: () => this.setDirty(),
        jumpOutOfField: () => this.send('jumpOutOfField', { field }),
        jumpToNextField: () => this.send('jumpToNextField', { field }),
      }
    },
  },
  created() {
    const machine = inputLineMachine.withContext({
      vm: this,
    })
    this.inputService = interpret(machine)
    this.inputService.start()
  },
  destroyed() {
    this.inputService.stop()
  },
  watch: {
    value() {
      // restart fsm when new value arrives
      this.inputService.stop()
      this.inputService.start()
    },
  },
  filters: {
    formatDate: dateTimeToGermanDate,
  },
}
</script>

<style lang="scss" scoped>
.col-3 {
  display: flex;

  .date-section {
    flex: 1 1 70%;
  }

  .link-section {
    flex: 1 1 30%;
  }
}

.document-link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.remove-link {
  color: #bdbdbd;

  &:hover {
    color: #2d3a43;
  }
}
</style>
