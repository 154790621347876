<template>
  <v-layout v-if="extractionData && hasDue">
    <v-flex>
      <v-list :three-line="hasText" :two-line="!hasText" class="elevation-1">
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon>update</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title> Wiedervorlage </v-list-tile-sub-title>
            <v-list-tile-title>
              {{ formattedDueDate }} (noch {{ fromNow }})
            </v-list-tile-title>
            <v-list-tile-sub-title>
              {{ extractionData.dueText }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn @click="removeDue" icon v-on="on">
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
              Wiedervorlage löschen
            </v-tooltip>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-flex>
  </v-layout>
</template>

<script>
import formatDate from 'date-fns/format'
import de from 'date-fns/locale/de'
import fromNow from 'date-fns/distance_in_words_to_now'
import documentsApi from '@/api/documentsApi'
import paymentdataApi from '@/api/paymentdataApi'
import * as Sentry from '@sentry/vue'

export default {
  name: 'HasExistentDueDate',
  props: ['extractionData', 'masterData', 'year'],
  computed: {
    selectedPayment() {
      if (this.masterData) {
        return this.masterData.payments.find((el) => el.year === this.year)
      }
      return null
    },
    hasText() {
      const text = this.extractionData.dueText
      return text && text.length > 0
    },
    hasDue() {
      return Boolean(this.extractionData.dueDate)
    },
    formattedDueDate() {
      if (this.hasDue) {
        const date = new Date(this.extractionData.dueDate)
        return formatDate(date, 'DD.MM.YYYY')
      }
      return ''
    },
    fromNow() {
      if (this.hasDue) {
        const date = new Date(this.extractionData.dueDate)
        return fromNow(date, { locale: de })
      }
      return ''
    },
  },
  methods: {
    async removeDue() {
      const id = this.extractionData.id
      try {
        await documentsApi.removeDue(id)
        if (this.hasText && this.selectedPayment) {
          const id = this.selectedPayment.id
          const newNote = await paymentdataApi.addNote(
            id,
            `Entfernte Wiedervorlage (${this.formattedDueDate}): ${this.extractionData.dueText}`
          )
          this.selectedPayment.notes.push(newNote)
        }
        this.extractionData.dueDate = null
      } catch (e) {
        Sentry.captureException(e)
      }
    },
  },
}
</script>

<style scoped></style>
