<template>
  <v-dialog persistent v-model="isDialog" width="800">
    <v-card class="content">
      <v-card-title primary-title><h3>Wiedervorlage setzen</h3></v-card-title>
      <v-card-text>
        <datepicker
          :allowed-dates="allowedDates"
          label="Datum"
          v-model="selectedDate"
        />
        <v-text-field box label="Anmerkung" v-model="dueText" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Abbrechen</v-btn>
        <v-btn :disabled="!selectedDate" @click="saveDate" color="primary"
          >Wiedervorlage Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as Sentry from '@sentry/vue'
import Datepicker from '@/components/dates/Datepicker'
import formatDate from 'date-fns/format'
import isFuture from 'date-fns/is_future'
import de from 'date-fns/locale/de'
import fromNow from 'date-fns/distance_in_words_to_now'
import documentsApi from '@/api/documentsApi'
import { isDialog } from '@/mixins/isDialog'

export default {
  name: 'ChangeDueDate',
  components: { Datepicker },
  mixins: [isDialog],
  props: ['extractionData'],
  data() {
    return {
      selectedDate: null,
      dueText: '',
    }
  },
  computed: {
    hasDue() {
      if (this.extractionData.dueDate) {
        return isFuture(this.extractionData.dueDate)
      }
      return false
    },
    formattedDueDate() {
      if (this.hasDue) {
        const date = new Date(this.extractionData.dueDate)
        return formatDate(date, 'DD.MM.YYYY')
      }
      return ''
    },
    fromNow() {
      if (this.hasDue) {
        const date = new Date(this.extractionData.dueDate)
        return fromNow(date, { locale: de })
      }
      return ''
    },
  },
  methods: {
    close() {
      this.selectedDate = null
      this.dueText = ''
      this.isDialog = false
    },
    allowedDates(value) {
      return isFuture(new Date(value))
    },
    async saveDate() {
      const id = this.extractionData.id
      const dueDate = this.selectedDate
      const dueText = this.dueText
      try {
        await documentsApi.setDue(id, dueDate, dueText)
        this.$set(this.extractionData, 'dueDate', this.selectedDate)
        this.$set(this.extractionData, 'dueText', this.dueText)
        this.close()
        this.$emit('savedDueDate', dueDate)
      } catch (e) {
        Sentry.captureException(e)
      }
    },
  },
  created() {
    this.selectedDate = null
  },
}
</script>

<style scoped></style>
