<template>
  <v-card v-if="masterData">
    <table class="text-xs-center pa-2" style="width: 100%; font-size: 16px">
      <tr>
        <td class="font-weight-bold text-xs-left text">Datenabgleich</td>
        <td>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-if="extractionReferenceNumber" v-on="on">{{
                extractionReferenceNumber.value
              }}</span>
              <span class="grey--text font-italic" v-else
                >Kassenzeichen nicht gefunden</span
              >
              <v-icon color="success" v-if="referenceNumberMatches"
                >check</v-icon
              >
              <div class="error--text" v-else>
                {{ referenceNumberMasterdata || '[leer]' }}
              </div>
            </template>
            <span v-if="referenceNumberMatches"
              >Kassenzeichen im Dokument entspricht Stammdaten</span
            >
            <span v-else>Kassenzeichen stimmen nicht überein</span>
          </v-tooltip>
        </td>
        <td>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-if="extractionIban" v-on="on">{{
                extractionIban.value | formatIban
              }}</span>
              <span class="grey--text font-italic" v-else
                >IBAN nicht gefunden</span
              >
              <v-icon color="success" v-if="ibanMatches">check</v-icon>
              <div class="error--text" v-else>
                <template v-if="ibanMasterdata">
                  {{ ibanMasterdata | formatIban }}
                </template>
                <span class="font-italic" v-else>IBAN nicht hinterlegt</span>
              </div>
            </template>
            <span v-if="referenceNumberMatches"
              >IBAN im Dokument entspricht Stammdaten</span
            >
            <span v-else>IBAN stimmt nicht überein</span>
          </v-tooltip>
        </td>
      </tr>
    </table>
  </v-card>
</template>
<script>
import { hasExtractionDocument } from '@/mixins/hasExtractionDocument'
import { formatIban } from '@/utils/paymentUtils'

export default {
  name: 'ExtractionDataComparator',
  mixins: [hasExtractionDocument],
  props: {
    masterData: {
      required: true,
    },
    extractionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ibanMasterdata() {
      return this.masterData.bank.iban || ''
    },
    referenceNumberMasterdata() {
      return this.masterData.referenceNumber || ''
    },

    referenceNumberMatches() {
      if (!this.extractionReferenceNumber) {
        return false
      }
      return (
        this.extractionReferenceNumber.value.toLowerCase() ===
        this.referenceNumberMasterdata.toLowerCase()
      )
    },

    ibanMatches() {
      if (!this.extractionIban) {
        return false
      }

      return (
        this.extractionIban.value.toLowerCase() ===
        this.ibanMasterdata.toLowerCase()
      )
    },
  },
  filters: {
    formatIban,
  },
}
</script>
