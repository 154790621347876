<template>
  <v-dialog v-model="isDialog" width="500" :fullscreen="true">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Zahlungshistorie Ortsteile</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="isDialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="currentEntry"
        :hide-actions="true"
      >
        <template v-slot:items="props">
          <td>{{ props.item.ags }}</td>
          <td>{{ props.item.municipality }}</td>
          <td>{{ props.item.note }}</td>
          <td>{{ props.item.sum | formatAmountToCurrency }}</td>
          <td>{{ props.item.saldo | formatAmountToCurrency }}</td>
          <td>{{ props.item.uniformMunicipalRateSince }}</td>
          <td class="text-xs-right">
            <v-btn
              _target="blank"
              :to="{
                name: 'PaymentDataView',
                params: { id: props.item.id, year: year },
              }"
              >Zur Gemeinde
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { taxSaldo, formatAmountToCurrency, taxSum } from '@/utils/paymentUtils'
import { isDialog } from '@/mixins/isDialog'

export default {
  name: 'ModalPaymentSummary',
  mixins: [isDialog],
  props: ['paymentSummary', 'year'],
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'AGS', sortable: false },
        { text: 'Gemeinde', sortable: false },
        { text: 'Bemerkungen', sortable: false },
        { text: 'Summe', sortable: false },
        { text: 'Saldo', sortable: false },
        { text: 'Einh. HS. seit', sortable: false },
        { text: '', sortable: false },
      ],
    }
  },
  computed: {
    currentEntry() {
      return this.paymentSummary.map((el) => {
        const payment = el.payments.find((el) => el.year === this.year)
        const result = { ...el, payment }
        if (payment) {
          if (payment.notes && payment.notes.length) {
            result.note = payment.notes[payment.notes.length - 1].text
          }
          result.sum = taxSum(payment)
          result.saldo = taxSaldo(payment)
        }

        return result
      })
    },
  },
  filters: {
    formatAmountToCurrency,
  },
}
</script>

<style scoped></style>
