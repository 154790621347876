<template>
  <v-menu
    bottom
    left
    offset-y
    transition="slide-x-transition"
    v-if="masterData"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>more_vert</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-tile :disabled="!hasDiff" @click="$emit('resetData')">
        <v-list-tile-title>Aktuelle Änderungen verwerfen</v-list-tile-title>
      </v-list-tile>
      <v-list-tile
        @click="$emit('setAsSubMunicipality')"
        v-if="masterData.parent === null"
      >
        <v-list-tile-title>Eingemeindung</v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="$emit('removeAsSubMunicipality')" v-else>
        <v-list-tile-title>Eingemeindung aufheben</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'MasterDataViewMenu',
  props: ['masterData', 'hasDiff'],
  data() {
    return {
      dialogs: {},
    }
  },
}
</script>

<style scoped></style>
