<template>
  <v-dialog v-model="isDialog" width="800">
    <v-card class="content">
      <v-card-title primary-title>
        <h3>Email Vorlage für Gutschriften</h3>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Empfänger"
          placeholder="max@mustermann.de"
          v-model="email.recipient"
        />
        <v-text-field
          label="Betreff"
          placeholder="Betrefftext"
          v-model="email.subject"
        />

        <v-textarea :rows="rowCount" label="Text" v-model="email.body" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Schließen</v-btn>
        <v-btn :href="emailLink" color="primary">Email-Programm öffnen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isDialog } from '@/mixins/isDialog'

export default {
  name: 'ModalCreditEntryEmail',
  mixins: [isDialog],
  props: ['emailTemplate'],
  data() {
    return {
      email: {
        subject: '',
        body: '',
        recipient: '',
      },
    }
  },
  computed: {
    rowCount() {
      return this.email.body.split('\n').length + 1
    },
    emailLink() {
      const { recipient, subject, body } = this.email
      return `mailto:${recipient}?subject=${encodeURI(
        subject
      )}&body=${encodeURI(body)}`
    },
  },
  methods: {
    close() {
      this.isDialog = false
    },
  },
  watch: {
    emailTemplate: {
      handler(value) {
        Object.assign(this.email, value)
      },
      immediate: true,
    },
  },
}
</script>

<style scoped></style>
