import isValidDate from 'date-fns/is_valid'

const hasExtractionDocument = {
  data() {
    return {
      documentDate: null,
    }
  },
  computed: {
    documentData() {
      if (this.extractionData) {
        const date = this.documentDate
        const document_id = this.extractionData.id
        return { date, document_id }
      }
    },
    currentExtractionFields() {
      if (this.extractionData) {
        return this.extractionData.fields
      }
      return {}
    },
    currentPdf() {
      if (this.extractionData) {
        return this.extractionData.pdf
      }
    },
    extractionReferenceNumber() {
      const e = this.currentExtractionFields.referenceNumber
      if (e && e.value) {
        return e
      }
      return null
    },
    extractionIban() {
      const e = this.currentExtractionFields.iban
      if (e && e.value) {
        return e
      }
      return null
    },
    extractionDocumentDate() {
      const e = this.currentExtractionFields.datum
      if (e && e.value) {
        return e
      }
      return null
    },
    extractionYear() {
      const e = this.currentExtractionFields.year
      if (e && e.value !== 'n/a') {
        return e
      }
      return null
    },
  },
  watch: {
    extractionData: {
      handler() {
        this.documentDate = ''
        if (this.extractionDocumentDate) {
          const [dd, mm, yyyy] = this.extractionDocumentDate.value.split('.')
          const date = `${yyyy}-${mm}-${dd}`
          if (isValidDate(new Date(date))) {
            this.documentDate = date
          }
        }
      },
      deep: true,
    },
  },
}

export { hasExtractionDocument }
