<template>
  <v-data-table :headers="headers" :items="notes">
    <template v-slot:items="props">
      <td>{{ props.item.postedBy }}</td>
      <td>{{ props.item.text }}</td>
      <td>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ fromNow(props.item.created_at) }}</span>
          </template>
          {{ noteDate(props.item.created_at) }}
        </v-tooltip>
      </td>
    </template>
  </v-data-table>
</template>

`
<script>
import formatDate from 'date-fns/format'
import { fromNowDe } from '@/utils/DateHelper'

export default {
  name: 'NoteListTable',
  props: ['notes'],
  data() {
    return {
      headers: [
        { text: 'Ersteller', sortable: false },
        { text: 'Text', sortable: false },
        { text: 'Datum', sortable: false },
      ],
    }
  },
  methods: {
    fromNow(date) {
      return `vor ${fromNowDe(new Date(date))}`
    },
    noteDate(date) {
      return formatDate(new Date(date), 'DD.MM.YYYY (HH:mm) [Uhr]')
    },
  },
}
</script>

<style scoped></style>
