<template>
  <v-dialog v-if="isEditor" v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-on="on" :disabled="!hasDiff">Speichern </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Getätigte Änderungen</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-layout>
          <v-flex xs1 />
          <v-flex xs4>
            <h2>Alter Wert</h2>
          </v-flex>
          <v-flex xs2 />
          <v-flex xs4>
            <h2>Neuer Wert</h2>
          </v-flex>
          <v-flex xs1 />
        </v-layout>

        <v-layout v-for="(value, key) in dotDiff" :key="key">
          <v-flex xs1 />
          <v-flex xs4>
            <v-text-field
              :label="label(key)"
              :value="fromOriginal(key)"
              readonly
              box
            />
          </v-flex>
          <v-flex xs2 />
          <v-flex xs4>
            <v-text-field
              :label="label(key)"
              :value="fromChanged(key)"
              readonly
              box
            />
          </v-flex>
          <v-flex xs1 />
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="close">Abbrechen</v-btn>
        <v-btn @click="save" color="success">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import fieldNames from '@/pages/masterDataView/fieldnames'
import dot from 'dot-object'

export default {
  name: 'ModalMasterdataDiff',
  props: ['original', 'changed', 'diff'],
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    dotDiff() {
      return dot.dot(this.diff)
    },
    hasDiff() {
      return Object.keys(this.diff).length > 0
    },
    isEditor() {
      return this.$can(this.$permissions.changeMasterdata)
    },
  },

  methods: {
    fromOriginal(path) {
      return dot.pick(path, this.original)
    },
    fromChanged(path) {
      return dot.pick(path, this.changed)
    },
    label(path) {
      const chops = path.split('.')
      const withoutNumbers = chops
        .filter((el) => /\d/.test(el) === false)
        .join('.')
      const translated = fieldNames[withoutNumbers]
      if (['ihkName', 'municipalRate'].includes(chops[0])) {
        const { year } = dot.pick([chops[0], chops[1]].join('.'), this.original)
        return translated.replace('{year}', year)
      }
      return translated
    },
    close() {
      this.dialog = false
    },
    save() {
      this.$emit('save')
      this.close()
    },
  },
}
</script>

<style scoped></style>
