<template>
  <input
    v-model="fieldValue"
    class="money-input"
    :class="isInvalid && 'red--text'"
    @blur="send('blur')"
    @click="focus"
    @focus="send('focus')"
    @input="send('input')"
    @keydown.tab.prevent="send('input.enter')"
    @keyup.enter="send('input.enter')"
    @keyup.esc="send('input.esc')"
    type="text"
    :disabled="!editable"
  />
</template>

<script>
import { formatAmountToCurrency, editableAmount } from '@/utils/paymentUtils'
import { Machine, interpret } from 'xstate'

const options = {
  actions: {
    elementActivated(context) {
      context.vm.$emit('elementActivated')
    },
    formatToCurrency: (context) => {
      context.vm.fieldValue = formatAmountToCurrency(context.vm.value)
    },
    formatToEditable: (context) => {
      context.vm.fieldValue = editableAmount(context.vm.value)
    },
    setInvalid: (context) => {
      context.vm.isInvalid = true
    },
    setValid: (context) => {
      context.vm.isInvalid = false
    },
    save(context) {
      const preparedNumber = `${context.vm.fieldValue}`
        .replace(/\./g, '') // removes dotes which separate thousands
        .replace(',', '.') // replaces the german "," with a float-valid "."

      // use toFixed to avoid floating point issues while transforming to cents
      const value = Number(Number(preparedNumber * 100).toFixed(0))

      context.vm.$emit('input', value)
    },
    jump(context) {
      context.vm.$emit('jumpToNextField')
    },
    jumpOutOfField(context) {
      context.vm.$emit('jumpOutOfField')
    },
    blur(context) {
      context.vm.$nextTick(() => {
        document.activeElement.blur()
      })
    },
  },
  guards: {
    isSaveable(context) {
      // Matches -123123,12 € | 12312432134 | -12.234.123,23
      // Non-Matches 1234.12,234
      const re = /^\s*-?((\d{1,3}(\.(\d){3})*)|\d*)(,\d{1,2})?\s*$/
      return re.test(context.vm.fieldValue)
    },
    valueHasChanged(context) {
      return context.vm.valueHasChanged()
    },
  },
}

const inputMachine = Machine(
  {
    id: 'inputMachine',
    initial: 'inactive',
    context: {
      vm: null,
    },
    states: {
      inactive: {
        entry: ['formatToCurrency', 'setValid'],
        on: {
          focus: {
            target: 'active',
            actions: ['formatToEditable', 'elementActivated'],
          },
        },
      },
      active: {
        on: {
          blur: [
            {
              target: 'inactive',
              cond: 'valueHasChanged',
              actions: ['save', 'jumpOutOfField'],
            },
            {
              target: 'inactive',
            },
          ],
          input: [
            {
              target: 'active',
              cond: 'isSaveable',
            },
            {
              target: 'invalid',
            },
          ],
          'input.enter': {
            target: 'inactive',
            actions: ['blur', 'save', 'jump'],
          },
          'input.esc': {
            target: 'inactive',
            actions: ['blur'],
          },
        },
      },
      invalid: {
        entry: ['setInvalid'],
        on: {
          blur: {
            target: 'inactive',
          },
          'input.esc': {
            target: 'inactive',
            actions: ['blur'],
          },
          input: [
            {
              target: 'active',
              actions: ['setValid'],
              cond: 'isSaveable',
            },
            {
              target: 'invalid',
            },
          ],
        },
      },
    },
  },
  options
)

export default {
  name: 'PaymentInlineEditMoney',
  props: ['value', 'editable'],
  data() {
    return {
      fieldValue: null,
      isInvalid: false,
    }
  },
  methods: {
    send(event) {
      this.inputService.send(event)
    },
    async focus() {
      await this.$nextTick()
      this.$el.focus()
      await this.$nextTick()
      this.$el.select()
    },
    valueHasChanged() {
      return this.fieldValue !== editableAmount(this.value)
    },
  },
  created() {
    const machine = inputMachine.withContext({
      vm: this,
    })
    this.inputService = interpret(machine)
    this.inputService.start()
  },
  destroyed() {
    this.inputService.stop()
  },
  watch: {
    value() {
      // restart fsm when new value arrives
      this.inputService.stop()
      this.inputService.start()
    },
  },
}
</script>

<style lang="scss" scoped>
.money-input {
  text-align: right;
  max-width: 100%;

  &[disabled] {
    color: #818181;
  }
}
</style>
