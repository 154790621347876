<template>
  <v-layout>
    <v-flex>
      <v-layout row wrap>
        <v-flex xs3>
          <v-text-field
            :disabled="!isCreating"
            :readonly="!isCreating"
            :label="labels.municipality"
            box
            v-model="masterData.municipality"
          />
        </v-flex>
        <v-flex xs3>
          <v-text-field
            :disabled="disabled"
            :label="labels.zip"
            box
            v-model="masterData.zip"
          />
        </v-flex>
        <v-flex xs3>
          <v-text-field
            :disabled="!isCreating"
            :readonly="!isCreating"
            :label="labels.ags"
            box
            v-model="masterData.ags"
          />
        </v-flex>
        <v-flex xs3>
          <v-text-field
            :disabled="disabled"
            :append-icon="!isCreating ? 'edit' : ''"
            :label="labels.referenceNumber"
            :readonly="!isCreating"
            @click:append="showReferenceNumberModal = true"
            box
            v-model="masterData.referenceNumber"
          />
        </v-flex>
        <v-flex xs3>
          <v-select
            :disabled="!isCreating"
            :items="companies"
            :label="labels.company_id"
            box
            item-text="name"
            item-value="id"
            v-model="masterData.company_id"
          />
        </v-flex>
        <v-flex xs3>
          <v-checkbox
            :disabled="disabled"
            :label="labels.isAgsOld"
            color="primary"
            v-model="masterData.isAgsOld"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <ModalEditReferenceNumber
      :master-data="masterData"
      v-model="showReferenceNumberModal"
    />
  </v-layout>
</template>
<script>
import fieldnames from '@/pages/masterDataView/fieldnames'
import ModalEditReferenceNumber from '@/pages/masterDataView/masterDataViewContent/ModalEditReferenceNumber'
import { mapGetters } from 'vuex'

export default {
  name: 'MasterdataMunicipality',
  components: { ModalEditReferenceNumber },
  props: {
    isCreating: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    masterData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showReferenceNumberModal: false,
    }
  },
  computed: {
    ...mapGetters(['companies']),
    labels() {
      return fieldnames
    },
  },
}
</script>
