<template>
  <v-card flat v-if="notes">
    <v-toolbar card>
      <v-toolbar-title class="body-3 grey--text text--darken-3"
        >{{ title }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn @click="close" icon><v-icon>close</v-icon></v-btn>
    </v-toolbar>
    <note-list-table :notes="notes" />
    <v-card-text v-if="validationErrors">
      <validation-errors :errors="validationErrors" />
    </v-card-text>
    <v-list two-line v-if="canCreate && isEditor">
      <v-subheader>Neue Bemerkung</v-subheader>
      <v-list-tile>
        <v-text-field
          :disabled="disabled"
          box
          flex
          hint="Min. 5 Zeichen"
          placeholder="Bemerkungstext.."
          @keyup.enter="sendNote"
          v-model="newNote"
        />
      </v-list-tile>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="close">Abbrechen</v-btn>
      <v-btn :disabled="!isSaveable" @click="sendNote" color="primary"
        >Speichern</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import NoteListTable from '@/components/notes/NoteListTable'
import masterdataApi from '@/api/masterdataApi'
import paymentdataApi from '@/api/paymentdataApi'
import ValidationErrors from '@/components/ValidationErrors'

export default {
  name: 'Notes',
  components: { ValidationErrors, NoteListTable },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },

    type: {
      type: String,
      required: true,
    },
    notes: {
      type: Array,
      required: true,
    },
    canCreate: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newNote: '',
      validationErrors: null,
    }
  },
  computed: {
    isSaveable() {
      return this.newNote.length >= 5
    },
    title() {
      return {
        paymentDataNotes: 'Bemerkungen Zahlungsdaten',
        masterDataNotes: 'Bemerkungen Stammdaten',
        paymentLineNotes: 'Bemerkungen Zahlung',
      }[this.type]
    },
    isEditor() {
      return this.$can(this.$permissions.addPaymentData)
    },
  },
  methods: {
    close() {
      this.newNote = ''
      this.validationErrors = null
      this.$emit('close')
    },
    async sendNote() {
      if (!this.isSaveable) {
        return
      }
      try {
        this.validationErrors = null
        const method = {
          paymentDataNotes: (id, note) => paymentdataApi.addNote(id, note),
          masterDataNotes: (id, note) => masterdataApi.addNote(id, note),
          paymentLineNotes: (id, note) =>
            paymentdataApi.addPaymentLineNote(id, note),
        }[this.type]

        const createdNote = await method(this.id, this.newNote)

        if (createdNote) {
          this.$root.setSnackbar('success', 'Bemerkung hinzugefügt')
          this.newNote = ''
          this.notes.unshift(createdNote)
          this.$emit('addNote', createdNote)
        }
      } catch (e) {
        this.validationErrors = e.errorObject
      }
    },
  },
}
</script>

<style scoped></style>
