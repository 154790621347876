import BaseApi from './BaseApi'

class MasterdataApi extends BaseApi {
  searchEntries(company_id, search, page = 1, per_page) {
    const params = {
      page,
    }
    if (company_id) {
      params.company_id = company_id
    }
    if (search) {
      params.search = search
    }
    if (per_page) {
      params.per_page = per_page
    }
    return this.get(`/masterData/search`, params)
  }

  getEntry(id) {
    return this.get(`/masterData/${id}`)
  }

  getChildrenOf(id) {
    return this.get(`masterData/${id}/getChildren`)
  }

  getChildrenPaymentSummary(id, year) {
    return this.get(`masterData/${id}/childrenPaymentSummary?year=${year}`)
  }

  changeReferenceNumber(masterDataId, referenceNumber) {
    return this.put(`masterData/${masterDataId}/changeReferenceNumber`, {
      referenceNumber,
    })
  }

  createEntry(masterData) {
    return this.post('masterData', masterData)
  }

  updateEntry(id, data) {
    return this.put(`masterData/${id}`, data)
  }

  getList(company) {
    return this.get(`stamm/${company}`)
  }

  addNote(id, text) {
    return this.post(`masterData/${id}/addNote`, { text })
  }

  setParentMunicipality(id, data) {
    return this.post(`masterData/${id}/setParentMunicipality`, data)
  }

  removeParentMunicipality(id) {
    return this.post(`masterData/${id}/setParentMunicipality`)
  }
}

export default new MasterdataApi()
