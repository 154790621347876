import { Machine } from 'xstate'

const interestFields = ['interest_additional', 'interest_refund']

const inputLineMachine = Machine(
  {
    id: 'inputLineMachine',
    initial: 'inactive',
    strict: true,
    states: {
      inactive: {
        entry: ['resetLineState'],
        on: {
          elementActivated: {
            target: 'active',
          },
        },
      },
      calculateInterest: {
        // A promise is always a sub-machine. So we're invoking the calcInterest service
        // and after this machine is done calculating we go back to the active state
        // and select in parallel the first input field
        invoke: {
          id: 'calculateInterest',
          src: (context) => {
            return context.vm.calcInterest()
          },
          onDone: {
            target: 'active',
          },
        },
        exit: ['goToFirstInterestField'],
      },

      active: {
        on: {
          elementActivated: {
            target: 'active',
          },

          jumpToNextField: [
            {
              cond: 'isTaxFieldAndHasInterest', // when the field is a taxField and we also have interest fields in this line
              target: 'calculateInterest',
            },

            {
              cond: 'hasOwnNextField',
              target: 'active',
              actions: ['selectNextOwn'],
            },

            {
              target: 'inactive',
              actions: ['saveLine', 'jumpToNextLine'],
            },
          ],
          jumpOutOfField: [
            {
              target: 'inactive',
              cond: 'isDirty',
              actions: ['saveLine'],
            },
            {
              target: 'inactive',
            },
          ],
        },
      },
    },
  },
  {
    actions: {
      goToFirstInterestField,
      selectNextOwn,
      saveLine,
      jumpToNextLine,
      resetLineState,
    },
    guards: {
      hasOwnNextField,
      isTaxFieldAndHasInterest,
      isDirty,
    },
  }
)

function goToFirstInterestField(context) {
  const { vm } = context

  const allEditableFields = vm.getInputFieldsInThisLine()
  const firstInterestField = allEditableFields.find((el) =>
    interestFields.includes(el.field)
  )

  if (firstInterestField) {
    firstInterestField.ref.focus()
  }
}

function selectNextOwn(context, transition) {
  let { field } = transition
  const { vm } = context

  const allEditableFields = vm.getInputFieldsInThisLine()
  const currentIndex = allEditableFields.findIndex((el) => el.field === field)
  const next = allEditableFields[currentIndex + 1]

  if (next) {
    next.ref.focus()
  }
}

function saveLine(context) {
  context.vm.fieldChanged()
  context.vm.$emit('saveLine')
}

function jumpToNextLine(context) {
  context.vm.$emit('jumpToNextLine')
}

function resetLineState(context) {
  context.vm.isDirty = false
}

function hasOwnNextField(context, event) {
  const { vm } = context
  const { field } = event
  const allEditableFields = vm.getInputFieldsInThisLine()

  const index = allEditableFields.findIndex((el) => el.field === field)
  return index + 1 < allEditableFields.length
}

function isTaxFieldAndHasInterest(context, event) {
  const { vm } = context
  const { field } = event
  const allEditableFields = vm.getInputFieldsInThisLine()
  const hasInterestFields = allEditableFields.some((el) =>
    interestFields.includes(el.field)
  )
  return field === 'tax' && hasInterestFields
}

function isDirty(context) {
  return context.vm.isDirty
}

export default inputLineMachine
