<template>
  <v-layout>
    <v-flex>
      <h2>Rechnungswesen</h2>
      <v-layout row wrap>
        <v-flex :key="key" v-for="key in accountingFields" xs6>
          <v-text-field
            :disabled="disabled"
            :label="labels[key]"
            box
            v-model="masterData[key]"
          />
        </v-flex>
      </v-layout>
      <h3>Bankdaten</h3>
      <v-layout>
        <v-flex xs4>
          <v-text-field
            :disabled="disabled"
            :label="labels['bank.iban']"
            :rules="[validations.iban]"
            box
            v-model="masterData.bank.iban"
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            :disabled="disabled"
            :label="labels['bank.bic']"
            :rules="[validations.bic]"
            box
            v-model="masterData.bank.bic"
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            :disabled="disabled"
            :label="labels['bank.bType']"
            box
            v-model="masterData.bank.bType"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import fieldnames from '@/pages/masterDataView/fieldnames'
import { isValidIBANNumber, isValidBic } from '@/utils/paymentUtils'

export default {
  name: 'MasterdataAccounting',
  props: {
    masterData: {},
    disabled: Boolean,
  },
  data() {
    return {
      validations: {
        iban: (value) => isValidIBANNumber(value) || 'Keine gültige IBAN',
        bic: (value) => isValidBic(value) || 'Keine gültige BIC',
      },
    }
  },
  computed: {
    accountingFields() {
      const result = ['referenceNumber', 'creditor']
      if (this.masterData.relatedCompany === 'mitgas') {
        result.push('creditorOld')
      }
      return result
    },
    labels() {
      return fieldnames
    },
  },
}
</script>
