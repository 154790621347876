<template>
  <v-dialog
    max-width="600"
    ref="dialog"
    @input="(value) => (isOpen = value)"
    :value="isOpen"
    attach=".payment-data"
    hide-overlay
  >
    <template #activator="{ on }">
      <v-icon small v-on="on">calculate</v-icon>
    </template>
    <v-card>
      <v-card-title class="headline">Zinsrechner </v-card-title>

      <v-layout>
        <v-flex xs6>
          <v-list dense two-line>
            <v-list-tile v-for="item in infoList.slice(0, 3)" :key="item.label">
              <v-list-tile-content>
                <v-list-tile-title>{{ item.value }}</v-list-tile-title>
                <v-list-tile-sub-title>{{ item.label }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-flex>
        <v-flex xs6>
          <v-list dense two-line>
            <v-list-tile v-for="item in infoList.slice(3)" :key="item.label">
              <v-list-tile-content>
                <v-list-tile-title>{{ item.value }}</v-list-tile-title>
                <v-list-tile-sub-title>{{ item.label }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-flex>
      </v-layout>

      <v-card-text>
        <datepicker v-model="dateFrom" label="Zeitraum von" />
        <datepicker v-model="dateTo" label="Zeitraum bis" />
        <v-text-field
          :value="paymentData.tax.value | formatAmountToCurrency"
          label="Gewerbesteuer"
          :readonly="true"
          :hint="`Grundlage der Berechnung: ${calculationTaxBaseFormatted}`"
          persistent-hint
          box
        />
        <v-text-field
          :value="fullMonths"
          label="Volle Monate"
          :readonly="true"
          box
        />
        <v-text-field
          :value="calculatedInterest.result | formatAmountToCurrency"
          label="Berechnete Zinsen"
          :readonly="true"
          box
        />
        <v-expansion-panel>
          <v-expansion-panel-content v-if="fullMonths > 0">
            <template v-slot:header>
              <strong>Berechnungsschritte</strong>
            </template>
            <v-card>
              <v-card-text>
                <v-data-table :items="calculationSteps" :headers="headers">
                  <template v-slot:items="{ item }">
                    <td>{{ item.month }}</td>
                    <td>{{ item.interest }}%</td>
                    <td>{{ item.result | formatAmountToCurrency }}</td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="setToAdditional">Nachzahlung </v-btn>
        <v-btn @click="setToRefund">Erstattung</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Datepicker from '@/components/dates/Datepicker'
import formatDate from 'date-fns/format'
import addDays from 'date-fns/add_days'
import diffMonths from 'date-fns/difference_in_months'
import { formatAmountToCurrency } from '@/utils/paymentUtils'
import interestRatesApi from '@/api/interestRatesApi'

export default {
  name: 'PaymentInterestCalculator',
  components: { Datepicker },
  inject: ['masterData'],
  props: {
    paymentData: {
      type: Object,
      required: true,
    },
    paymentYear: {
      type: [Number, String],
      required: true,
    },
    documentData: {
      type: [Object, Boolean],
      required: true,
    },
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      calculatedInterest: {},
      isOpen: false,
      headers: [
        { text: 'Monat', sortable: false },
        { text: 'Zins', sortable: false },
        { text: 'Betrag (kumuliert)', sortable: false },
      ],
    }
  },
  computed: {
    documentDate() {
      return this.documentData?.date ?? null
    },
    infoList() {
      return [
        { label: 'AGS', value: this.masterData.ags },
        { label: 'Kommune', value: this.masterData.municipality },
        {
          label: 'Verwaltung',
          value: this.masterData.addressAdministrativeMunicipality.city,
        },
        { label: 'Kassenzeichen', value: this.masterData.referenceNumber },
        { label: 'Kreditor', value: this.masterData.creditor },
      ]
    },
    fullMonths() {
      if (this.dateFrom && this.dateTo) {
        const fullMonths = diffMonths(
          new Date(this.dateTo),
          new Date(this.dateFrom)
        )
        if (fullMonths > 0) {
          return fullMonths
        }
      }
      return 0
    },
    calculationTaxBase() {
      return this.calculatedInterest.calculationBase
    },
    calculationTaxBaseFormatted() {
      return formatAmountToCurrency(this.calculationTaxBase)
    },
    calculationSteps() {
      if (this.calculatedInterest.calculationSteps) {
        return this.calculatedInterest.calculationSteps.map((el, index) => {
          return {
            ...el,
            month: index + 1,
          }
        })
      }
      return []
    },
  },
  created() {
    const periods = this.$store.state.defaultInterestPeriods
    const periodForYear = periods?.find(
      (el) => String(el.year) === String(this.paymentYear)
    )?.starts_at

    this.dateFrom = periodForYear ?? `${this.paymentYear}-01-04`

    if (this.documentDate) {
      const documentDatePlusThree = addDays(new Date(this.documentDate), 3)
      this.dateTo = formatDate(documentDatePlusThree, 'YYYY-MM-DD')
    }
  },
  methods: {
    setToRefund() {
      const { result } = this.calculatedInterest
      this.paymentData.interest_refund.value = result
      this.isOpen = false
    },
    setToAdditional() {
      const { result } = this.calculatedInterest
      this.paymentData.interest_additional.value = result
      this.isOpen = false
    },
    async calculate() {
      if (!this.isOpen) {
        return
      }
      const startDate = formatDate(new Date(this.dateFrom), 'DD.MM.YYYY')
      const endDate = formatDate(new Date(this.dateTo), 'DD.MM.YYYY')
      const taxBase = this.paymentData.tax.value
      const value = await interestRatesApi.calculateRate(
        taxBase,
        startDate,
        endDate
      )
      this.calculatedInterest = value
    },
  },
  watch: {
    fullMonths: {
      immediate: true,
      handler: 'calculate',
    },
    isOpen(isOpen) {
      if (isOpen) {
        this.calculate()
      }
    },
  },
  filters: {
    formatAmountToCurrency,
  },
}
</script>
<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
