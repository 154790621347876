<template>
  <v-list class="py-0">
    <v-list-tile>
      <v-layout>
        <v-flex v-if="$slots.default">
          <slot />
        </v-flex>
        <template v-else>
          <v-flex xs2>
            <slot name="col1"></slot>
          </v-flex>
          <v-flex xs3 class="text-xs-right px-0">
            <slot name="col2"></slot>
          </v-flex>
          <v-flex xs3 class="text-xs-right px-0">
            <slot name="col3"></slot>
          </v-flex>
          <v-flex class="text-xs-right px-0" xs4>
            <slot name="col3"></slot>
          </v-flex>
        </template>
      </v-layout>
    </v-list-tile>
  </v-list>
</template>

<script>
export default {
  name: 'PaymentTableHeading',
}
</script>

<style scoped></style>
