<template>
  <v-container class="py-2">
    <v-layout>
      <v-flex>
        <v-spacer />
        <v-btn :disabled="!canSave" :loading="isSaving" @click="savePayment"
          >Speichern</v-btn
        >
        <v-btn
          :disabled="!canSave"
          :loading="isSaving"
          @click="saveAndCompleteDialog = true"
          >Speichern und Beenden</v-btn
        >
      </v-flex>
    </v-layout>
    <confirm-modal
      :ok-action="savePaymentAndComplete"
      text="Wollen Sie die Bearbeitung dieses Dokuments abschließen? Das Dokument wird anschließend archivert."
      v-model="saveAndCompleteDialog"
    />
  </v-container>
</template>
<script>
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ExtractionViewSave',
  components: { ConfirmModal },
  props: {
    canSave: {
      type: Boolean,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saveAndCompleteDialog: false,
    }
  },
  methods: {
    savePayment() {
      this.$emit('savePayment')
    },
    savePaymentAndComplete() {
      this.$emit('savePaymentAndComplete')
    },
  },
}
</script>
