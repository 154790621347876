<template>
  <div>
    <v-menu bottom left transition="slide-x-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>

      <v-list>
        <template v-if="!extractionData.dueDate">
          <v-subheader>Wiedervorlage</v-subheader>
          <v-list-tile @click="dialogs.isDueDate = true">
            <v-list-tile-title>Wiedervorlage setzen</v-list-tile-title>
          </v-list-tile>
          <v-divider />
        </template>

        <template v-if="masterData">
          <v-subheader>Stammdaten</v-subheader>
          <v-list-tile
            @click="dialogs.newPaymentYear = true"
          >
            <v-list-tile-title>Neues Zahlungsjahr</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="$emit('removeMasterData')" v-if="masterData">
            <v-list-tile-title>Andere Gemeinde wählen</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="$emit('showMasterData')" v-if="masterData">
            <v-list-tile-title>Stammdaten bearbeiten</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            :disabled="!$can($permissions.changeMasterdata)"
            @click="dialogs.changeReferenceNumber = true"
            v-if="masterData"
          >
            <v-list-tile-title
              >Kassenzeichen in Stammdaten übernehmen</v-list-tile-title
            >
          </v-list-tile>
          <v-divider />
          <v-subheader>Bemerkungen</v-subheader>
          <v-list-tile @click="$emit('showNotes', 'masterDataNotes')">
            <v-list-tile-content>
              <v-list-tile-title> Bemerkungen Stammdaten </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-chip disabled>{{ masterData.notes.length }}</v-chip>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile
            @click="$emit('showNotes', 'paymentDataNotes')"
            v-if="selectedPayment"
          >
            <v-list-tile-content>
              <v-list-tile-title> Bemerkungen Zahlungsdaten </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-chip disabled>{{ selectedPayment.notes.length }}</v-chip>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider />
        </template>
        <v-subheader>Dokument</v-subheader>
        <v-list-tile v-if="masterData" @click="assignMasterDataToDocument">
          <v-list-tile-title>Zugewiesene Gemeinde speichern</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="dialogs.archiveDocument = true">
          <v-list-tile-title>Dokument archivieren</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="dialogs.deleteDocument = true">
          <v-list-tile-title>Dokument löschen</v-list-tile-title>
        </v-list-tile>
        <v-list-tile
          v-if="masterData && hasCreditEntry"
          @click="dialogs.emailTemplate = true"
        >
          <v-list-tile-title>Email Gutschriften</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>

    <change-due-date
      :extraction-data="extractionData"
      v-model="dialogs.isDueDate"
      @savedDueDate="(d) => $emit('savedDueDate', d)"
    />

    <modal-add-payment-year
      :master-data="masterData"
      v-model="dialogs.newPaymentYear"
    />

    <modal-credit-entry-email
      :email-template="emailTemplate"
      v-model="dialogs.emailTemplate"
    />

    <confirm-modal
      :ok-action="deleteCurrentDocument"
      text="Wollen Sie dieses Dokument wirklich löschen?"
      title="Löschen bestätigen"
      v-model="dialogs.deleteDocument"
    />

    <confirm-modal
      :ok-action="archiveCurrentDocument"
      text="Wollen Sie dieses Dokument wirklich archivieren?"
      title="Archivieren bestätigen"
      v-model="dialogs.archiveDocument"
    />

    <confirm-modal
      :ok-action="changeReferenceNumber"
      :text="`Wollen Sie das Kassenzeichen des Dokuments in die Stammdaten der Gemeinde ${masterData.municipality} (${masterData.ags}) übernehmen? `"
      title="Neues Kassenzeichen setzen"
      v-if="masterData"
      v-model="dialogs.changeReferenceNumber"
    />
  </div>
</template>

<script>
import ChangeDueDate from '@/pages/extractionView/ChangeDueDate'
import ModalAddPaymentYear from '@/pages/extractionView/extractionViewMenu/ModalAddPaymentYear'
import ConfirmModal from '@/components/modals/ConfirmModal'
import documentsApi from '@/api/documentsApi'
import { filterCreditEntriesWithDocumentId } from '@/utils/filters'
import { getCreditEntryEmailText } from '@/utils/generateEmailTemplate'
import ModalCreditEntryEmail from '@/pages/extractionView/extractionViewMenu/ModalCreditEntryEmail'

export default {
  name: 'ExtractionViewMenu',
  components: {
    ModalCreditEntryEmail,
    ConfirmModal,
    ModalAddPaymentYear,
    ChangeDueDate,
  },
  props: ['masterData', 'extractionData', 'year', 'isLocked'],
  data() {
    return {
      dialogs: {
        isDueDate: false,
        newPaymentYear: false,
        archiveDocument: false,
        deleteDocument: false,
        changeReferenceNumber: false,
        emailTemplate: false,
      },
    }
  },
  computed: {
    selectedPayment() {
      if (this.masterData) {
        return this.masterData.payments.find((el) => el.year === this.year)
      }
      return null
    },
    hasCreditEntry() {
      if (this.selectedPayment) {
        return (
          filterCreditEntriesWithDocumentId(
            this.selectedPayment.payment_lines,
            this.extractionData.id
          ).length > 0
        )
      }
      return false
    },
    emailTemplate() {
      if (this.selectedPayment) {
        return getCreditEntryEmailText(this.masterData, this.extractionData)
      }
      return null
    },
  },
  methods: {
    async archiveCurrentDocument() {
      await documentsApi.archiveDocument(this.extractionData.id)
      this.dialogs.archiveDocument = false
      this.$root.setSnackbar('success', 'Dokument wurde archiviert')
      this.$emit('documentArchived')
    },
    async deleteCurrentDocument() {
      try {
        await documentsApi.deleteDocument(this.extractionData.id)
        this.$root.setSnackbar('success', 'Dokument wurde gelöscht')
        this.$emit('documentDeleted')
      } catch (e) {
        const error = e.errorObject
        this.$root.setSnackbar('error', error)
      } finally {
        this.dialogs.deleteDocument = false
      }
    },
    async assignMasterDataToDocument() {
      await documentsApi.assignMasterDataToDocument(
        this.extractionData.id,
        this.masterData.id
      )
      this.$root.setSnackbar('success', 'Dokument wurde verknüpft')
    },
    async changeReferenceNumber() {
      this.$emit('changeReferenceNumber')
    },
  },
}
</script>

<style scoped></style>
