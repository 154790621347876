<template>
  <v-layout class="px-4 ma-0 tablerow">
    <v-flex class="px-0">
      <v-layout>
        <v-flex xs2 class="col-1">
          <slot name="col1" />
        </v-flex>
        <v-flex xs7 class="col-2">
          <v-layout>
            <v-flex xs3><slot name="col2-1" /></v-flex>
            <v-flex xs3><slot name="col2-2" /></v-flex>
            <v-flex xs3><slot name="col2-3" /></v-flex>
            <v-flex xs3><slot name="col2-4" /></v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3 class="col-3">
          <slot name="col3" />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'PaymentTableRowWrap',
}
</script>

<style scoped>
.tablerow:hover {
  background-color: white;
}

.col-1 {
}

.col-2 {
  text-align: right;
}
.col-3 {
  margin-left: 2rem;
}
</style>
