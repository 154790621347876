<template>
  <v-flex align-center d-flex justify-center v-if="masterData">
    <v-layout row wrap>
      <v-flex class="pr-2" shrink>
        <strong>{{ masterData.company.name }}</strong>
      </v-flex>
      <v-flex class="pr-2" shrink>
        <strong>{{ masterData.municipality }}</strong> ({{ masterData.ags }})
      </v-flex>
      <v-flex class="pr-2" shrink v-if="masterData.parent">
        <strong>Ortst. von: </strong>{{ masterData.parent.municipality }}
      </v-flex>
      <v-flex class="pr-2" shrink v-if="masterData.suburbanisedAt">
        <strong>Eingem. seit: </strong
        >{{ masterData.suburbanisedAt | formatDate }}
      </v-flex>
      <v-flex class="pr-2" shrink v-if="masterData.uniformMunicipalRateSince">
        <strong>Einh. HS: </strong>{{ masterData.uniformMunicipalRateSince }}
      </v-flex>
      <v-flex class="pr-2" shrink>
        <strong>Kreditor: </strong>{{ masterData.creditor }}
      </v-flex>
    </v-layout>
  </v-flex>
</template>
<script>
import formatDate from 'date-fns/format'

export default {
  name: 'MasterDataSummary',
  props: {
    masterData: {
      type: Object,
      required: true,
    },
  },
  filters: {
    formatDate(date) {
      return formatDate(new Date(date), 'DD.MM.YYYY')
    },
  },
}
</script>
<style lang="scss" scoped>
.item {
  padding: 0 16px;
}
</style>
