const lockRoutingWhenChanged = {
  beforeRouteLeave(to, from, next) {
    if (this.hasDiff === undefined) {
      throw new Error(
        'Mixin lockRoutingWhenChanged requires a property hasDiff'
      )
    }

    if (this.hasDiff) {
      const answer = window.confirm(
        'Sie haben ungespeicherte Änderungen. Diese gehen beim Verlassen verloren. '
      )
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
}

export { lockRoutingWhenChanged }
