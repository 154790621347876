<template>
  <app-layout :append-content-container="false">
    <LoadingData v-if="!extractionData" />
    <v-layout fill-height row v-else>
      <pdf-viewer
        :extraction-data="extractionData"
        :is-snip-activated="isSnipActivated"
        :key="viewerKey"
        @downloadPDF="downloadPDF"
        @snipAreaSelected="snipAreaSelected"
        @snipModeChange="snipModeChange"
        class="pdf-in-extraction"
        v-if="extractionData"
      />
      <v-flex class="fill-height payment-data">
        <v-alert :value="true" type="error" v-if="errorMessage">
          {{ errorMessage }}
        </v-alert>
        <v-container class="py-2" fluid grid-list-md>
          <v-layout row>
            <master-data-summary v-bind="{ masterData }" v-if="masterData" />
            <v-spacer />
            <extraction-view-menu
              @changeReferenceNumber="changeReferenceNumber"
              @documentArchived="replaceRouteWithDashboard"
              @documentDeleted="replaceRouteWithDashboard"
              @removeMasterData="masterData = null"
              @savedDueDate="savedDueDate"
              @showMasterData="editMasterDataModal = true"
              @showNotes="showNotes"
              v-bind="{ masterData, extractionData, year, isLocked: true }"
            />
          </v-layout>

          <template v-if="extractionData"></template>
        </v-container>

        <template v-if="masterData">
          <v-container class="py-2" fluid grid-list-md>
            <ExtractionDataComparator
              :extraction-data="extractionData"
              :master-data="masterData"
            />
            <v-layout align-start class="mt-2" justify-start>
              <v-flex xs3>
                <payment-year-picker :master-data="masterData" v-model="year" />
              </v-flex>
              <v-flex xs6>
                <ExtractionViewDocumentDate
                  v-model="documentDate"
                  :is-locked="isLocked"
                  @changeDocumentDate="changeDocumentDate"
                />
              </v-flex>
              <v-flex xs3 v-if="paymentSummary.length">
                <modal-payment-summary
                  v-model="dialogs.paymentSummary"
                  :payment-summary="paymentSummary"
                  :year="year"
                />
                <v-btn @click="dialogs.paymentSummary = true"
                  >Zahlungen Ortsteile</v-btn
                >
              </v-flex>
            </v-layout>

            <has-existent-due-date
              v-bind="{ masterData, extractionData, year }"
            />
          </v-container>

          <payment-data-view-content
            ref="paymentDataViewContent"
            v-bind="{
              assignDocumentMode: !isLocked,
              documentData,
              masterData,
              year,
              currentDocument: extractionData,
            }"
          />

          <ExtractionViewSave
            v-if="isEditor"
            :can-save="hasDiff"
            :is-saving="isSaving"
            @savePayment="savePayment"
            @savePaymentAndComplete="savePaymentAndComplete"
          />

          <ProcessedYears
            @setYear="setPaymentYear"
            v-bind="{ masterData, extractionData }"
          />

          <ModalMasterDataView
            :create="false"
            @dataSaved="loadMasterdata"
            :id="masterData.id"
            v-model="editMasterDataModal"
          />

          <AllNotesModal
            :id="notesModal.id"
            :notes="notesModal.notes"
            :type="notesModal.type"
            v-model="notesModal.visible"
          />
          <ModalExtractionTextSelection
            v-model="textExtractionModal.visible"
            :extraction-text="textExtractionModal.data"
          />
        </template>
        <v-flex v-else>
          <v-container grid-list-md>
            <v-alert :value="true" type="warning"
              >Es konnte keine passende Gemeinde gefunden werden. Bitte zuerst
              eine Gemeinde wählen
            </v-alert>
            <search-masterdata-typeahead
              :disabled="isLocked"
              @itemSelected="loadMasterdata"
            />
            <template v-if="suggestedMasterDataEntry">
              <strong>Vorschlag: </strong>
              <a
                @click="loadMasterdata(suggestedMasterDataEntry.id)"
                class="accent--text"
                >{{ suggestedMasterDataEntry.municipality }} ({{
                  suggestedMasterDataEntry.ags
                }}) / {{ suggestedMasterDataEntry.referenceNumber }}</a
              >
            </template>
          </v-container>
        </v-flex>
      </v-flex>
    </v-layout>
  </app-layout>
</template>

<script>
import { hasDiff } from '@/mixins/hasDiff'
import { hasExtractionDocument } from '@/mixins/hasExtractionDocument'
import { lockRoutingWhenChanged } from '@/mixins/lockRoutingWhenChanged'

import masterdataApi from '@/api/masterdataApi'
import authApi from '@/api/authApi'
import documentsApi from '@/api/documentsApi'
import paymentdataApi from '@/api/paymentdataApi'

import PdfViewer from '@/components/pdf/PDFViewer'
import AppLayout from '@/components/AppLayout'
import PaymentDataViewContent from '@/pages/paymentDataView/PaymentDataViewContent'
import SearchMasterdataTypeahead from '@/components/SearchMasterdataTypeahead'
import ExtractionViewMenu from '@/pages/extractionView/ExtractionViewMenu'
import HasExistentDueDate from '@/pages/extractionView/HasExistentDueDate'
import ModalMasterDataView from '@/pages/extractionView/ModalMasterDataView'
import ExtractionViewDocumentDate from '@/pages/extractionView/ExtractionViewDocumentDate'

import formatDate from 'date-fns/format'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import ExtractionDataComparator from '@/pages/extractionView/ExtractionDataComparator'
import ProcessedYears from '@/pages/extractionView/ProcessedYears'
import MasterDataSummary from '@/components/MasterDataSummary'
import ExtractionViewSave from '@/pages/extractionView/ExtractionViewSave'
import PaymentYearPicker from '@/components/PaymentYearPicker'
import settingsApi from '@/api/settingsApi'
import LoadingData from '@/components/LoadingData'
import AllNotesModal from '@/components/notes/AllNotesModal'
import ModalExtractionTextSelection from '@/pages/extractionView/ModalExtractionTextSelection'
import * as Sentry from '@sentry/vue'
import set from 'lodash/set'
import ModalPaymentSummary from '@/pages/paymentDataView/ModalPaymentSummary'

export default {
  name: 'ExtractionView',
  components: {
    ModalPaymentSummary,
    ExtractionViewDocumentDate,
    ModalExtractionTextSelection,
    AllNotesModal,
    LoadingData,
    PaymentYearPicker,
    ExtractionViewSave,
    MasterDataSummary,
    ProcessedYears,
    ExtractionDataComparator,
    HasExistentDueDate,
    ExtractionViewMenu,
    SearchMasterdataTypeahead,
    PaymentDataViewContent,
    AppLayout,
    PdfViewer,
    ModalMasterDataView,
  },
  props: ['id'],
  mixins: [hasExtractionDocument, hasDiff, lockRoutingWhenChanged],
  data() {
    return {
      masterData: null,
      paymentSummary: [],
      extractionData: null,
      year: '',
      lockInterval: null,
      errorMessage: '',
      viewerKey: document.documentElement.clientWidth,
      suggestedMasterDataEntry: null,
      bypassDiffFilter: false,
      isSnipActivated: false,
      notesModal: {
        visible: false,
        id: null,
        notes: [],
        type: '',
      },
      editMasterDataModal: false,
      isSaving: false,
      textExtractionModal: {
        visible: false,
        data: [],
      },
      dialogs: {
        paymentSummary: false,
      },
    }
  },
  provide() {
    return {
      documentDate: this.documentDate,
    }
  },

  computed: {
    diffObserved() {
      return {
        masterData: this.masterData,
        extractionData: this.extractionData,
        documentDate: this.documentDate,
      }
    },

    isLocked() {
      return !!this.errorMessage
    },
    isEditor() {
      return !(this.isLocked || !this.$can(this.$permissions.addPaymentData))
    },
    changedPayments() {
      const savedLines = []
      if (this.hasDiff) {
        const changedPayments = this.diff?.masterData?.payments
        if (changedPayments) {
          forEach(changedPayments, (changedPaymentYear, key) => {
            const completePaymentYear = this.masterData.payments[key]

            if (changedPaymentYear.payment_lines) {
              Object.keys(changedPaymentYear.payment_lines).forEach(
                (paymentLineIndex) => {
                  const paymentLine =
                    completePaymentYear.payment_lines[paymentLineIndex]

                  if (!paymentLine.payment_data_id) {
                    paymentLine.payment_data_id = completePaymentYear.id
                  }
                  const isBooked = [
                    'tax',
                    'interest_additional',
                    'interest_refund',
                  ].some((type) => !paymentLine[type].booked)
                  if (isBooked) {
                    savedLines.push(paymentLine)
                  }
                }
              )
            }
          })
        }
      }
      return savedLines
    },
  },
  methods: {
    showNotes(type) {
      this.notesModal.visible = true
      this.notesModal.type = type
      if (type === 'masterDataNotes') {
        this.notesModal.id = this.masterData.id
        this.notesModal.notes = this.masterData.notes
      } else if (type === 'paymentDataNotes') {
        const paymentYear = this.masterData.payments.find(
          (el) => el.year === this.year
        )
        this.notesModal.id = paymentYear.id
        this.notesModal.notes = paymentYear.notes
      }
    },
    closeNotes() {
      this.notesModal.visble = false
      this.notesModal.type = ''
    },
    async snipAreaSelected(boundingBox) {
      this.textExtractionModal.data = []
      const { guid } = this.extractionData
      const queryData = { ...boundingBox, guid }
      this.isSnipActivated = false
      documentsApi.getTextFromRect(queryData).then((data) => {
        this.textExtractionModal.visible = true
        this.textExtractionModal.data = data
      })
    },
    snipModeChange() {
      this.isSnipActivated = !this.isSnipActivated
    },
    diffFilter(diff) {
      if (this.bypassDiffFilter) {
        // when this flag is activated no diff will be detected, thus the router can navigate away without questions
        return {}
      }
      // when masterData diff has notes, remove it from diff
      if (get(diff, 'masterData.notes')) {
        delete diff.masterData.notes
      }
      // when masterData.payments diff has notes, remove them from notes
      if (get(diff, 'masterData.payments')) {
        forEach(diff.masterData.payments, function (payment, key) {
          if (payment.notes) {
            delete payment.notes
          }
          if (payment.payment_lines) {
            forEach(payment.payment_lines, (paymentLine, key) => {
              delete paymentLine.notes
              if (isEmpty(paymentLine)) {
                delete payment.payment_lines[key]
              }
            })
            if (isEmpty(payment.payment_lines)) {
              delete payment.payment_lines
            }
          }
          // when payment is empty after removing notes, delete the payment from list
          if (isEmpty(payment)) {
            delete diff.masterData.payments[key]
          }
        })
        // when payment list is empty after pre-processing, delete it from diff
        if (isEmpty(diff.masterData.payments)) {
          delete diff.masterData.payments
        }
      }
      // when masterData is empty after cleaning notes, remove it from diff
      if (isEmpty(diff.masterData)) {
        delete diff.masterData
      }

      if (diff.extractionData) {
        ;['updated_at', 'locked_at', 'locker'].forEach((key) => {
          delete diff.extractionData[key]
        })
        if (isEmpty(diff.extractionData)) {
          delete diff.extractionData
        }
      }

      return diff
    },

    async setPaymentYear(year) {
      if (year) {
        this.year = String(year)
      } else if (this.extractionYear) {
        this.year = this.extractionYear && this.extractionYear.value
      } else {
        const { value: defaultDocumentYear } = await settingsApi.getEntry(
          this.masterData.company_id,
          'defaultDocumentYear'
        )
        this.year = String(defaultDocumentYear)
      }
    },

    async getExtractedData(id) {
      const extractionData = await documentsApi.getEntry(id)
      this.extractionData = extractionData
      this.suggestedMasterDataEntry = extractionData.suggestion
      await this.setMasterData(extractionData.master_data)
      await this.getPaymentSummary()
      const user = await authApi.user()
      const { locker } = this.extractionData
      if (locker.id === user.id) {
        this.lockInterval = setInterval(this.keepLocking, 30000)
      } else {
        this.errorMessage = `Das Dokument wird gerade von ${locker.username} bearbeitet`
      }
    },

    async keepLocking() {
      try {
        this.extractionData = await documentsApi.keepLocking(
          this.extractionData.id
        )
      } catch (e) {
        Sentry.captureException(e)
      }
    },
    async loadMasterdata(id) {
      const result = await masterdataApi.getEntry(id)
      await this.setMasterData(result)
      await this.getPaymentSummary()
    },

    async getPaymentSummary() {
      if (this.masterData) {
        this.paymentSummary = await masterdataApi.getChildrenPaymentSummary(
          this.masterData.id,
          this.year
        )
      }
    },

    setMasterData(masterData) {
      return new Promise(async (resolve, reject) => {
        this.masterData = masterData
        if (masterData && !this.extractionData.master_data_id) {
          await documentsApi.assignMasterDataToDocument(
            this.extractionData.id,
            this.masterData.id
          )
        }
        // we have to use $nextTick to let all watchers do their job
        this.$nextTick(() => {
          this.setDiffObservable(this.diffObserved)
          resolve()
        })

        if (masterData) {
          await this.$store.dispatch('setCurrentCompany', masterData.company_id)
          if (!this.year) {
            this.setPaymentYear()
          }
        }
      })
    },
    async savedDueDate(date) {
      const formatted = formatDate(new Date(date), 'DD.MM.YYYY')
      this.bypassDiffFilter = true
      this.$root.setSnackbar(
        'success',
        `Dokument wurde auf Wiedervorlage ${formatted} gesetzt`
      )
      this.$router.push({ name: 'Dashboard' })
    },

    async changeDocumentDate(newDate) {
      const date = newDate.split('-').reverse().join('.')
      const fieldsCopy = JSON.parse(JSON.stringify(this.extractionData.fields))
      set(fieldsCopy, 'datum.value', date)
      try {
        const { fields } = await documentsApi.changeDocumentFields(
          this.extractionData.id,
          fieldsCopy
        )
        this.extractionData.fields = fields
      } catch (e) {
        Sentry.captureException(e)
      }
    },

    async savePaymentAndComplete() {
      try {
        this.isSaving = true
        const savedLines = this.changedPayments
        await paymentdataApi.savePaymentLines(savedLines)
        await documentsApi.archiveDocument(this.extractionData.id)
        // Reset master data diff
        this.bypassDiffFilter = true
        this.$root.setSnackbar(
          'success',
          'Einträge wurden gespeichert und archiviert'
        )
        this.$router.replace({ name: 'Dashboard' })
      } catch {
        this.$root.setSnackbar(
          'error',
          'Einträge konnten nicht gespeichert werden'
        )
      } finally {
        this.isSaving = false
      }
    },

    async savePayment() {
      try {
        this.isSaving = true
        const savedLines = this.changedPayments
        await paymentdataApi.savePaymentLines(savedLines)
        // Reset master data diff
        await this.loadMasterdata(this.masterData.id)
        this.$root.setSnackbar('success', 'Einträge wurden gespeichert')
      } catch {
        this.$root.setSnackbar(
          'error',
          'Einträge konnten nicht gespeichert werden'
        )
      } finally {
        this.isSaving = false
      }
    },

    resize() {
      this.viewerKey = document.documentElement.clientWidth
    },
    replaceRouteWithDashboard() {
      this.bypassDiffFilter = true
      this.$router.replace({ name: 'Dashboard' })
    },
    async downloadPDF() {
      await documentsApi.downloadPDF(this.extractionData.pdf)
    },
    async changeReferenceNumber() {
      if (!this.extractionReferenceNumber) {
        this.$root.setSnackbar(
          'error',
          'Kein Kassenzeichen im Dokument gefunden'
        )
      }
      const referenceNumber = this.extractionReferenceNumber.value
      try {
        await masterdataApi.changeReferenceNumber(
          this.masterData.id,
          referenceNumber
        )
        this.$root.setSnackbar('success', 'Kassenzeichen wurde aktualisiert')
        this.loadMasterdata(this.masterData.id)
      } catch (e) {
        const errorObject = e.errorObject
        if (errorObject) {
          const { referenceNumber } = errorObject
          this.$root.setSnackbar('error', referenceNumber)
        }
      }
    },
  },
  watch: {
    id: {
      handler: 'getExtractedData',
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy() {
    documentsApi.releaseLock(this.id)
    clearInterval(this.lockInterval)
    window.removeEventListener('resize', this.resize)
  },
}
</script>

<style lang="scss">
.payment-data {
  margin-left: 50%;
  position: relative;
}

.pdf-in-extraction {
  position: fixed;
  top: 64px;
  width: 50%;
  height: calc(100% - 64px);
}
</style>
