<template>
  <v-layout v-if="children && children.length" class="my-2">
    <v-flex>
      <h2>Untergeordnete Gemeinden</h2>
      <v-card>
        <v-list>
          <v-list-group no-action>
            <v-list-tile slot="activator">
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ children.length }} eingemeindete
                  Ortschaften</v-list-tile-title
                >
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              two-line
              v-for="child in children"
              :key="child.id"
              :to="{
                name: 'MasterDataView',
                params: { id: child.id },
              }"
            >
              <v-list-tile-content>
                <v-list-tile-title
                  >{{ child.ags }} {{ child.municipality }}</v-list-tile-title
                >
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ChildMunicipalitiesListing',
  props: ['children'],
}
</script>

<style scoped></style>
