<template>
  <v-dialog persistent v-if="masterData" v-model="isDialog" width="500">
    <v-card class="content">
      <v-card-title primary-title
        ><h3>Neues Zahlungsjahr erstellen</h3></v-card-title
      >
      <v-card-text>
        <validation-errors :errors="errors" v-if="errors" />
        <yearpicker label="Neues Zahlungsjahr" v-model="year" />
        <v-text-field box label="IHK Name" v-model="ihkName" />
        <v-text-field box label="Hebesatz" v-model="municipalRate" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Abbrechen</v-btn>
        <v-btn @click="save" color="primary">Erstellen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import paymentdataApi from '@/api/paymentdataApi'
import Yearpicker from '@/components/dates/Yearpicker'
import ValidationErrors from '@/components/ValidationErrors'
import { isDialog } from '@/mixins/isDialog'

export default {
  name: 'ModalAddPaymentYear',
  components: { ValidationErrors, Yearpicker },
  mixins: [isDialog],
  props: ['masterData'],
  data() {
    return {
      year: '',
      ihkName: '',
      municipalRate: '',
      errors: null,
    }
  },
  computed: {
    newestPaymentYear() {
      const allYears = this.masterData.payments
        .map((el) => {
          return Number(el.year)
        })
        .sort()
      return allYears[allYears.length - 1] || new Date().getFullYear()
    },
  },
  methods: {
    async save() {
      const id = this.masterData.id
      try {
        this.errors = null
        const result = await paymentdataApi.createPaymentYear(
          id,
          this.year,
          this.ihkName,
          this.municipalRate
        )
        this.masterData.payments.push(result)
        this.close()
        this.$root.setSnackbar(
          'success',
          `Das Zahlungsjahr ${this.year} wurde erstellt.`
        )
        this.$emit('addPaymentYear', result)
      } catch (e) {
        this.errors = e.errorObject
      }
    },
    close() {
      this.isDialog = false
    },
  },
  watch: {
    isDialog: {
      immediate: true,
      handler(isOpen) {
        if (isOpen) {
          this.year = this.newestPaymentYear + 1
          const { value: ihkName } = this.masterData.ihkName.find(
            (el) => Number(el.year) === this.newestPaymentYear
          )
          const { value: municipalRate } = this.masterData.municipalRate.find(
            (el) => Number(el.year) === this.newestPaymentYear
          )
          this.ihkName = ihkName
          this.municipalRate = municipalRate
        }
      },
    },
  },
}
</script>

<style scoped></style>
