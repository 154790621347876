<template>
  <v-layout>
    <v-flex>
      <h2>Kontakt</h2>
      <v-layout row>
        <v-flex
          :key="'contact-' + index"
          v-for="(contact, index) in masterData.contact"
        >
          <v-layout column>
            <v-flex>
              <h3>{{ contact.title }}</h3>
            </v-flex>
            <v-flex :key="key" lg6 md8 v-for="key in contactFields" xs12>
              <v-text-field
                :disabled="disabled"
                :label="labels['contact.' + key]"
                box
                v-model="contact[key]"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import fieldnames from '@/pages/masterDataView/fieldnames'

export default {
  name: 'MasterdataContactFields',
  props: {
    masterData: {},
    disabled: Boolean,
  },
  computed: {
    contactFields() {
      return ['contactName', 'phone', 'fax', 'email']
    },
    labels() {
      return fieldnames
    },
  },
}
</script>
