<template>
  <v-layout>
    <v-flex>
      <h2>Gewerbesteuerliche Betriebstätte (Netzzugehörigkeit)</h2>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        hide-actions
      >
        <template v-slot:items="props">
          <td>{{ props.item.from | toMonthYear }}</td>
          <td>{{ props.item.to | toMonthYear }}</td>
          <td v-if="!disabled">
            <v-btn @click="editItem(props.item)" icon>
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn @click="deleteItem(props.item)" icon>
              <v-icon>delete</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="!disabled" @click="addItem">Netzzugeh. anlegen</v-btn>
      </v-card-actions>

      <v-dialog max-width="800" v-model="showEditDialog">
        <v-card>
          <v-card-title>
            <h3>Gewerbesteuerliche Betriebstätte (Netzzugehörigkeit)</h3>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex lg6 md8 xs12>
                  <monthpicker
                    :label="labels['belongedToGrid.from']"
                    :dialog-to="dialogTo"
                    :items="items"
                    :edited-item="editedItem"
                    v-model="dialogFrom"
                  />
                </v-flex>
                <v-flex lg6 md8 xs12>
                  <monthpicker
                    :label="labels['belongedToGrid.to']"
                    :dialog-from="dialogFrom"
                    :items="items"
                    :edited-item="editedItem"
                    v-model="dialogTo"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog" color="blue darken-1" flat
              >Abbrechen</v-btn
            >
            <v-btn @click="saveDialog" color="blue darken-1" flat
              >Speichern</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
<script>
import Monthpicker from '@/components/dates/Monthpicker'
import fieldnames from '@/pages/masterDataView/fieldnames'
import { toMonthYear } from '@/utils/DateHelper'

export default {
  name: 'BelongedToGridEditor',
  components: { Monthpicker },
  props: {
    value: {
      type: Array,
    },
    disabled: Boolean,
  },
  data() {
    return {
      dialogFrom: null,
      dialogTo: null,
      headers: [
        { text: 'Von', sortable: false },
        { text: 'Bis', sortable: false },
        { text: '', sortable: false },
      ],
      editedItem: null,
      showEditDialog: false,
    }
  },
  computed: {
    items: {
      get() {
        if (this.value) {
          return [...this.value]
        }
        return []
      },
      set(value) {
        this.$emit('input', [...value])
      },
    },
    labels() {
      return fieldnames
    },
  },
  methods: {
    deleteItem(item) {
      this.items = this.items.filter((el) => el !== item)
    },
    addItem() {
      this.editItem({ from: null, to: null })
    },
    editItem(item) {
      this.editedItem = item
      this.dialogFrom = item.from
      this.dialogTo = item.to
      this.showEditDialog = true
    },
    saveDialog() {
      if (this.items.includes(this.editedItem)) {
        this.editedItem.from = this.dialogFrom
        this.editedItem.to = this.dialogTo
      } else {
        this.items = [
          ...this.items,
          { from: this.dialogFrom, to: this.dialogTo },
        ]
      }
      this.closeDialog()
    },
    closeDialog() {
      this.editedItem = null
      this.dialogFrom = null
      this.dialogTo = null
      this.showEditDialog = false
    },
    sortItems() {
      this.items.sort(function (a, b) {
        const [fromA, fromB] = [new Date(a.from), new Date(b.from)]
        const [toA, toB] = [new Date(a.to), new Date(b.to)]
        let result = fromA - fromB
        if (a.from === b.from) result = toA - toB
        return result
      })
    },
  },
  watch: {
    items() {
      this.sortItems()
    },
  },
  filters: {
    toMonthYear,
  },
}
</script>
