<template>
  <v-dialog v-model="show" width="600px">
    <v-card>
      <v-toolbar card>
        <v-toolbar-title class="body-3 grey--text text--darken-3"
          >Gefundene Texte</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text>
        <ul>
          <li
            :key="item.text + index"
            @click="copyValue(item.text)"
            style="cursor: pointer"
            v-for="(item, index) in extractionText"
          >
            {{ item.text }}
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="show = false">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalExtractionTextSelection',
  props: ['value', 'extractionText'],
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async copyValue(text) {
      await navigator.clipboard.writeText(text)
      this.$root.setSnackbar('success', `${text} wurde kopiert`)
    },
  },
}
</script>

<style scoped></style>
