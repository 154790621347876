<template>
  <v-dialog v-model="modal" width="500">
    <template v-slot:activator="{ on }">
      <v-card class="activator">
        <v-card-title v-on="on">
          {{ label }}
          <v-spacer />
          <v-icon>edit</v-icon>
        </v-card-title>
      </v-card>
    </template>
    <v-card>
      <v-card-title primary-title
        ><h3>{{ title }}</h3></v-card-title
      >
      <v-divider />
      <v-card-text class="content">
        <v-layout column justify-center>
          <v-flex :key="i" class="my-3" v-for="(item, i) in list">
            <v-layout row>
              <v-flex offset-xs3 xs2>
                <v-subheader>{{ item.year }}</v-subheader>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  :disabled="disabled"
                  :label="`Wert ${item.year}`"
                  hide-details
                  v-model="item.value"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="!disabled" @click="modal = false">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditYearValueList',
  props: ['label', 'list', 'title', 'disabled'],
  data() {
    return {
      modal: false,
    }
  },
}
</script>

<style scoped>
.activator {
  cursor: pointer;
}

.content {
  height: 500px;
  overflow-y: scroll;
}
</style>
