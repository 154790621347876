<template>
  <v-container :key="masterData.id" class="masterdata-form" grid-list-md>
    <MasterdataMunicipality
      :disabled="disabled"
      :is-creating="isCreating"
      :master-data="masterData"
    />

    <MasterdataParentMunicipality
      :disabled="disabled"
      :master-data="masterData"
    />

    <parent-municipality-card :parent="masterData.parent" />

    <child-municipalities-listing :children="children" />

    <MasterdataAdministrativeMunicipality
      :disabled="disabled"
      :master-data="masterData"
    />

    <MasterdataContactFields :disabled="disabled" :master-data="masterData" />

    <MasterdataAccounting :disabled="disabled" :master-data="masterData" />

    <BelongedToGridEditor
      :disabled="disabled"
      v-model="masterData.belongedToGrid"
    />

    <MasterdataOther :disabled="disabled" :master-data="masterData" />

    <v-layout row v-if="!isCreating" wrap>
      <v-flex xs12>
        <v-card>
          <notes
            :id="masterData.id"
            :notes="masterData.notes"
            type="masterDataNotes"
          />
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row v-if="!isCreating" wrap>
      <v-flex md6>
        <edit-year-value-list
          :disabled="disabled"
          :label="hebesatzLabel"
          :list="masterData.municipalRate"
          title="Hebesätze"
        />
      </v-flex>
      <v-flex md6>
        <edit-year-value-list
          :disabled="disabled"
          :label="ihkLabel"
          :list="masterData.ihkName"
          title="IHK Zugehörigkeit"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ChildMunicipalitiesListing from '@/pages/masterDataView/masterDataViewContent/ChildMunicipalitiesListing'
import ParentMunicipalityCard from '@/pages/masterDataView/masterDataViewContent/ParentMunicipalityCard'
import fieldNames from '@/pages/masterDataView/fieldnames'
import Notes from '@/components/notes/Notes'
import BelongedToGridEditor from '@/pages/masterDataView/masterDataViewContent/BelongedToGridEditor'
import MasterdataParentMunicipality from '@/pages/masterDataView/masterDataViewContent/MasterdataParentMunicipality'
import MasterdataAdministrativeMunicipality from '@/pages/masterDataView/masterDataViewContent/MasterdataAdministrativeMunicipality'
import MasterdataContactFields from '@/pages/masterDataView/masterDataViewContent/MasterdataContactFields'
import MasterdataAccounting from '@/pages/masterDataView/masterDataViewContent/MasterdataAccounting'
import MasterdataOther from '@/pages/masterDataView/masterDataViewContent/MasterdataOther'
import MasterdataMunicipality from '@/pages/masterDataView/masterDataViewContent/MasterdataMunicipality'
import EditYearValueList from '@/pages/masterDataView/masterDataViewContent/EditYearValueList'

export default {
  name: 'MasterDataViewContent',
  components: {
    EditYearValueList,
    MasterdataMunicipality,
    MasterdataOther,
    MasterdataAccounting,
    MasterdataContactFields,
    MasterdataAdministrativeMunicipality,
    MasterdataParentMunicipality,
    BelongedToGridEditor,
    Notes,
    ParentMunicipalityCard,
    ChildMunicipalitiesListing,
  },
  props: {
    masterData: {
      type: Object,
      required: true,
    },
    children: {
      type: Array,
    },
    isCreating: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
  computed: {
    labels() {
      return fieldNames
    },
    hebesatzLabel() {
      const rates = this.masterData.municipalRate
      if (rates.length === 0) {
        return
      }
      const last = rates[rates.length - 1]
      return `Hebesatz ${last.year}: ${last.value ? last.value : '—'}`
    },

    ihkLabel() {
      const ihk = this.masterData.ihkName
      if (ihk.length) {
        const last = ihk[ihk.length - 1]
        return `IHK ${last.year}: ${last.value ? last.value : '—'}`
      }
      return null
    },

    ihkList() {
      return [...this.masterData.ihkName].reverse().map((el) => {
        return `${el.year}: ${el.value || '—'}`
      })
    },

    hebesatzList() {
      return [...this.masterData.municipalRate].reverse().map((el) => {
        return `${el.year}: ${el.value || '—'}`
      })
    },
  },
}
</script>

<style>
.masterdata-form .theme--light.v-input--is-disabled input {
  color: rgba(0, 0, 0, 0.75);
}

.masterdata-form .pale input {
  color: rgba(0, 0, 0, 0.38) !important;
}
</style>
