<template>
  <v-layout v-if="masterData.parent">
    <v-flex>
      <v-layout row wrap>
        <v-flex xs3>
          <v-text-field
            :value="masterData.parent.municipality"
            box
            disabled
            label="Aufnehmende Kommune"
          />
        </v-flex>
        <v-flex xs3>
          <v-text-field
            box
            disabled
            label="AGS aufnehmende Kommune"
            v-model="masterData.parent.ags"
          />
        </v-flex>
        <v-flex xs3>
          <datepicker
            :disabled="disabled"
            :label="labels.suburbanisedAt"
            v-model="masterData.suburbanisedAt"
          />
        </v-flex>
        <v-flex xs3>
          <yearpicker
            :disabled="disabled"
            :label="labels.uniformMunicipalRateSince"
            v-model="masterData.uniformMunicipalRateSince"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import Datepicker from '@/components/dates/Datepicker'
import Yearpicker from '@/components/dates/Yearpicker'
import fieldnames from '@/pages/masterDataView/fieldnames'

export default {
  name: 'MasterdataParentMunicipality',
  components: { Datepicker, Yearpicker },
  props: {
    masterData: {},
    disabled: Boolean,
  },
  computed: {
    labels() {
      return fieldnames
    },
  },
}
</script>
