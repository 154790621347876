<template>
  <v-container class="py-2" grid-list-md v-if="selectedPayment">
    <v-layout>
      <v-flex xs12>
        <v-card class="grey lighten-4">
          <payment-table-row-wrap>
            <template #col2-1><strong>Gew.St</strong></template>
            <template #col2-2><strong title="alt">Zins</strong></template>
            <template #col2-3
              ><strong title="Nachzahlungszinsen">Nachz.Zins</strong></template
            >
            <template #col2-4
              ><strong title="Erstattungszinsen">Erst.Zins</strong></template
            >
            <template #col3><strong>Bescheiddatum</strong></template>
          </payment-table-row-wrap>

          <v-divider />

          <payment-table-heading class="white">
            <strong>Vorauszahlungen</strong>
          </payment-table-heading>

          <payment-table-listing
            :year="year"
            :allow-change-rows="false"
            :document-data="documentData"
            :editable="editable"
            :has-interest="false"
            :has-tax="true"
            :override-titles="[
              'Quartal I',
              'Quartal II',
              'Quartal III',
              'Quartal IV',
            ]"
            :payment-data="prePayments"
            :protected-lines="4"
            @showDocument="showDocument"
          />

          <payment-table-heading class="white">
            <strong>Korrekturzahlungen</strong>
          </payment-table-heading>

          <payment-table-listing
            :year="year"
            :document-data="documentData"
            :editable="editable"
            :has-interest="false"
            :has-tax="true"
            :payment-data="secondaryPrePayments"
            @addNewLine="(v) => addNewLine('secondaryPrePayments', v)"
            @removeLine="removeLine"
            @showDocument="showDocument"
          />

          <payment-table-heading class="white">
            <strong>Nachzahlung Folgejahr</strong>
          </payment-table-heading>

          <payment-table-listing
            :year="year"
            :document-data="documentData"
            :editable="editable"
            :has-interest="true"
            :has-tax="true"
            :payment-data="additionalPayments"
            @addNewLine="(v) => addNewLine('additionalPayments', v)"
            @removeLine="removeLine"
            @showDocument="showDocument"
          />

          <payment-table-heading class="white">
            <strong>Abschlusszahlung</strong>
          </payment-table-heading>

          <payment-table-listing
            :year="year"
            :document-data="documentData"
            :editable="editable"
            :has-interest="true"
            :has-tax="true"
            :payment-data="finalPayments"
            @addNewLine="(v) => addNewLine('finalPayments', v)"
            @removeLine="removeLine"
            @showDocument="showDocument"
          />

          <payment-table-heading class="white">
            <strong>Betriebsprüfung</strong>
          </payment-table-heading>

          <payment-table-listing
            :year="year"
            :document-data="documentData"
            :editable="editable"
            :has-interest="true"
            :has-tax="true"
            :payment-data="taxAuditPayments"
            @addNewLine="(v) => addNewLine('taxAuditPayments', v)"
            @removeLine="removeLine"
            @showDocument="showDocument"
          />

          <payment-table-heading class="white">
            <strong>Ergebnis</strong>
          </payment-table-heading>

          <payment-table-summary
            :year="year"
            :selected-payment="selectedPayment"
          />
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
  <v-container v-else>
    <v-layout>
      <v-flex>
        <v-alert :value="true" type="error"
          >Es wurde kein Zahlungsjahr gefunden
        </v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import PaymentTableListing from '@/pages/paymentDataView/paymentDataViewContent/PaymentTableListing'
import PaymentTableHeading from '@/pages/paymentDataView/paymentDataViewContent/PaymentTableHeading'
import PaymentTableSummary from '@/pages/paymentDataView/paymentDataViewContent/PaymentTableSummary'
import PaymentTableRowWrap from '@/pages/paymentDataView/paymentDataViewContent/PaymentTableRowWrap'

export default {
  name: 'PaymentDataViewContent',
  components: {
    PaymentTableRowWrap,
    PaymentTableSummary,
    PaymentTableHeading,
    PaymentTableListing,
  },
  provide() {
    return {
      masterData: this.masterData,
    }
  },
  props: {
    assignDocumentMode: {
      type: Boolean,
      required: true,
    },
    documentData: {
      type: Object,
    },
    year: {
      type: String,
    },
    masterData: {
      type: Object,
      required: true,
    },
    currentDocument: {
      type: Object,
    },
  },
  data() {
    return {
      saveAndCompleteDialog: false,
    }
  },
  computed: {
    payments() {
      if (this.masterData.payments) {
        return this.masterData.payments
      }
      return []
    },
    paymentYears() {
      let result = [...this.payments].map((el) => Number(el.year))
      result.sort()
      result.reverse()
      return result.map((year) => `${year}`)
    },
    selectedPayment() {
      return this.payments.find((el) => el.year === this.year)
    },
    prePayments() {
      return this.selectedPayment.payment_lines.filter(
        (el) => el.type === 'prePayments'
      )
    },
    secondaryPrePayments() {
      return this.selectedPayment.payment_lines.filter(
        (el) => el.type === 'secondaryPrePayments'
      )
    },
    taxAuditPayments() {
      return this.selectedPayment.payment_lines.filter(
        (el) => el.type === 'taxAuditPayments'
      )
    },
    additionalPayments() {
      return this.selectedPayment.payment_lines.filter(
        (el) => el.type === 'additionalPayments'
      )
    },
    finalPayments() {
      return this.selectedPayment.payment_lines.filter(
        (el) => el.type === 'finalPayments'
      )
    },
    editable() {
      return (
        this.assignDocumentMode && this.$can(this.$permissions.addPaymentData)
      )
    },
  },
  methods: {
    ...mapActions(['setCurrentCompany']),
    showDocument(document_id) {
      this.$emit('showDocument', document_id)
    },
    addNewLine(type, data) {
      const newLine = {
        type,
        ...data,
      }

      this.selectedPayment.payment_lines.push(newLine)
      this.selectedPayment.payment_lines.forEach((line, index) => {
        line.index = index
      })
    },
    removeLine(line) {
      const index = this.selectedPayment.payment_lines.indexOf(line)
      if (index >= 0) {
        this.selectedPayment.payment_lines.splice(index, 1)
      }
    },
  },
}
</script>
