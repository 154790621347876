<template>
  <v-dialog lazy v-model="modal" width="500">
    <v-card class="content" v-if="paymentLine">
      <v-list two-line>
        <v-subheader class="title"
          >Bescheiddaten {{ paymentLine.type | paymentTypeToName }}</v-subheader
        >
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>{{
              paymentLine.tax.value | formatAmountToCurrency
            }}</v-list-tile-title>
            <v-list-tile-sub-title>Gewerbesteuerbetrag</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-divider />
      <v-list>
        <v-subheader>Alle Bescheiddaten</v-subheader>
        <v-list-tile
          :class="i < docDates.length - 1 && 'grey--text'"
          :key="i"
          v-for="(item, i) in docDates"
          >{{ item }}
          <template v-if="i === docDates.length - 1">(Aktuell)</template>
        </v-list-tile>
      </v-list>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="modal = false">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import formatDate from 'date-fns/format'
import { formatAmountToCurrency, paymentTypeToName } from '@/utils/paymentUtils'

export default {
  name: 'PaymentLineDocumentDatesModal',
  props: ['value', 'paymentLine'],
  computed: {
    modal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    docDates() {
      return this.paymentLine.documentDates.map((el) => {
        return formatDate(el.date, 'DD.MM.YYYY')
      })
    },
  },
  filters: {
    formatAmountToCurrency,
    paymentTypeToName,
  },
}
</script>
