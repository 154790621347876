<template>
  <div>
    <v-divider />
    <div v-for="(payment, index) in paymentData" :key="index">
      <payment-table-line
        :year="year"
        :is-protected="paymentData.length <= protectedLines"
        @jumpToNextLine="selectNextLine(index)"
        @removeLine="(line) => $emit('removeLine', line)"
        @showDocument="showDocument"
        @showNotes="showNotes"
        @showDateList="showDateList"
        ref="paymentLine"
        v-bind="{
          editable,
          documentData,
          hasTax,
          hasInterest,
          title: getTitle(index),
        }"
        v-model="paymentData[index]"
      />
      <v-divider />
    </div>

    <div v-if="showDummyRow">
      <payment-table-line
        :year="year"
        v-model="newEntry"
        v-bind="{
          editable: false,
          documentData,
          hasTax,
          hasInterest,
          canHaveDelete: false,
          canHaveNotes: false,
          canHaveInterestCalculator: false,
        }"
      />
      <v-divider />
    </div>

    <div v-if="showNewRow" class="new-payment-line">
      <v-divider />
      <payment-table-line
        :year="year"
        :key="`newField-${paymentData.length + 1}`"
        @saveLine="addNewLine"
        ref="newRow"
        v-bind="{
          editable,
          documentData,
          hasTax,
          hasInterest,
          canHaveDelete: false,
          canHaveNotes: false,
          canHaveInterestCalculator: false,
        }"
        v-model="newEntry"
        @showDocument="showDocument"
      />
      <v-divider />
    </div>
    <AllNotesModal
      :id="notesModal.selectedLine.id"
      :notes="notesModal.selectedLine.notes"
      type="paymentLineNotes"
      v-if="notesModal.selectedLine"
      v-model="notesModal.visible"
    />
    <PaymentLineDocumentDatesModal
      :payment-line="dateListModal.paymentLine"
      v-model="dateListModal.visible"
    />
  </div>
</template>

<script>
import PaymentTableLine from '@/pages/paymentDataView/paymentDataViewContent/paymentTableListing/PaymentTableLine'
import AllNotesModal from '@/components/notes/AllNotesModal'

import PaymentLineDocumentDatesModal from '@/pages/paymentDataView/paymentDataViewContent/paymentTableListing/PaymentLineDocumentDatesModal'

function emptyEntry() {
  return {
    interest: { value: 0, booked: true },
    tax: { value: 0 },
    interest_refund: { value: 0 },
    interest_additional: { value: 0 },
    documentDates: [],
  }
}

export default {
  name: 'PaymentTableListing',
  components: {
    PaymentLineDocumentDatesModal,
    AllNotesModal,
    PaymentTableLine,
  },
  props: {
    year: {
      type: String,
    },
    documentData: {
      type: Object,
    },
    paymentData: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    hasTax: {
      type: Boolean,
      default: true,
    },
    hasInterest: {
      type: Boolean,
      default: true,
    },
    protectedLines: {
      type: Number,
      default: 0,
    },
    overrideTitles: {
      type: Array,
      default: () => [],
    },
    allowChangeRows: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newEntry: emptyEntry(),
      currentCursor: -1,
      prepareForLineSwitch: false,
      notesModal: {
        visible: false,
        selectedLine: null,
      },
      dateListModal: {
        visible: false,
        paymentLine: null,
      },
    }
  },
  computed: {
    isDocumentOpen() {
      if (this.documentData) {
        return Boolean(this.documentData.document_id)
      }
      return false
    },
    showDummyRow() {
      const isEmpty = this.paymentData.length === 0
      const isNotEditable = this.editable === false
      return isEmpty && isNotEditable
    },
    showNewRow() {
      const isEditable = this.editable
      const canChangeRows = this.allowChangeRows

      return isEditable && canChangeRows
    },
  },
  methods: {
    addNewLine() {
      this.$emit('addNewLine', this.newEntry)
      this.newEntry = emptyEntry()
      this.$nextTick(() => {
        this.$refs.newRow.focusLine()
      })
    },
    showNotes(line) {
      if (!line.notes) {
        this.$set(line, 'notes', [])
      }
      this.notesModal.selectedLine = line
      this.notesModal.visible = true
    },
    showDateList(line) {
      this.dateListModal.visible = true
      this.dateListModal.paymentLine = line
    },

    isCurrentlyOpenDocument(payment) {
      if (this.isDocumentOpen) {
        const dateEntry = this.lastDateEntry(payment)
        if (dateEntry) {
          return dateEntry.document_id === this.documentData.document_id
        }
      }
      return false
    },

    showDocument(document_id) {
      this.$emit('showDocument', document_id)
    },
    getTitle(index) {
      if (this.overrideTitles.length === 0) {
        return ''
      }
      if (this.overrideTitles.length !== this.paymentData.length) {
        throw new Error(
          'override titles must have the same length as payment data'
        )
      }
      return this.overrideTitles[index]
    },

    selectNextLine(currentIndex) {
      const nextLine = this.$refs.paymentLine[currentIndex + 1]
      if (nextLine) {
        nextLine.focusLine()
      }
    },
  },
  watch: {
    year() {
      this.newEntry = emptyEntry()
    },
  },
}
</script>

<style>
.new-payment-line {
  margin-top: -1px;
  background: white;
}
</style>
