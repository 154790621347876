<template>
  <v-layout align-center fill-height>
    <v-flex class="text-xs-center">
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
        small
      ></v-progress-circular>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: 'LoadingData',
}
</script>
