<template>
  <div>
    <v-divider />
    <div>
      <payment-table-line
        :year="year"
        v-model="paymentsInterestSum"
        v-bind="{
          editable: false,
          hasTax: false,
          hasInterest: true,
          title: 'Zinssumme',
          canHaveDelete: false,
          canHaveNotes: false,
          canHaveInterestCalculator: false,
        }"
      />
      <payment-table-line
        :year="year"
        v-model="paymentsSum"
        v-bind="{
          editable: false,
          hasTax: true,
          hasInterest: true,
          hasInterestAdditional: false,
          hasInterestRefund: false,
          title: 'Summe',
          canHaveDelete: false,
          canHaveNotes: false,
          canHaveInterestCalculator: false,
        }"
      />
      <v-divider />

      <payment-table-row-wrap>
        <template #col1>GewSt. gem. Ber.</template>
        <template #col2-1>
          <payment-inline-edit-money
            v-model="selectedPayment.businessTax"
            :editable="true"
            @jumpToNextField="businessTaxChanged"
            label="GewSt. gem. Berechnung"
          />
        </template>
      </payment-table-row-wrap>

      <v-divider />

      <payment-table-line
        :year="year"
        v-model="paymentsSaldo"
        v-bind="{
          editable: false,
          hasTax: true,
          hasInterest: false,
          hasInterestRefund: false,
          hasInterestAdditional: false,
          title: 'Saldo',
          canHaveDelete: false,
          canHaveNotes: false,
          canHaveInterestCalculator: false,
        }"
      />
      <v-divider />
    </div>
  </div>
</template>

<script>
import PaymentInlineEditMoney from '@/pages/paymentDataView/paymentDataViewContent/PaymentInlineEditMoney'
import PaymentTableLine from '@/pages/paymentDataView/paymentDataViewContent/paymentTableListing/PaymentTableLine'
import {
  interestSum,
  taxSum,
  allPayments,
  taxSaldo,
  formatAmountToCurrency,
  interestAdditionalSum,
  interestRefundSum,
  interestAllSum,
} from '@/utils/paymentUtils'
import PaymentTableRowWrap from '@/pages/paymentDataView/paymentDataViewContent/PaymentTableRowWrap'

import paymentdataApi from '@/api/paymentdataApi'

export default {
  name: 'PaymentTableSummary',
  components: {
    PaymentTableRowWrap,
    PaymentTableLine,
    PaymentInlineEditMoney,
  },
  props: {
    year: {
      type: String,
    },
    selectedPayment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    allPayments() {
      return allPayments(this.selectedPayment)
    },
    sumOfTaxPayments() {
      return taxSum(this.selectedPayment)
    },

    sumOfInterestPayments() {
      return interestSum(this.selectedPayment)
    },

    sumOfInterestAdditionalPayments() {
      return interestAdditionalSum(this.selectedPayment)
    },

    sumOfInterestRefundPayments() {
      return interestRefundSum(this.selectedPayment)
    },

    sumOfAllInterestPayments() {
      return interestAllSum(this.selectedPayment)
    },

    paymentsInterestSum() {
      return {
        tax: { value: 0 },
        interest: { value: this.sumOfInterestPayments },
        interest_additional: { value: this.sumOfInterestAdditionalPayments },
        interest_refund: { value: this.sumOfInterestRefundPayments },
      }
    },

    paymentsSum() {
      return {
        tax: { value: this.sumOfTaxPayments },
        interest: { value: this.sumOfAllInterestPayments },
        interest_additional: { value: 0 },
        interest_refund: { value: 0 },
      }
    },

    paymentsSaldo() {
      return {
        tax: { value: taxSaldo(this.selectedPayment) },
        interest: { value: 0 },
        interest_additional: { value: 0 },
        interest_refund: { value: 0 },
      }
    },
  },
  methods: {
    async businessTaxChanged() {
      await paymentdataApi.updateBusinessTax(
        this.selectedPayment.id,
        this.selectedPayment.businessTax
      )
      const amount = formatAmountToCurrency(this.selectedPayment.businessTax)
      this.$root.setSnackbar(
        'success',
        `GewSt. gem. Berechnung wurde auf den Wert ${amount} gesetzt.`
      )
    },
  },
}
</script>
