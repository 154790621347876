<template>
  <v-dialog fullscreen v-model="showModal">
    <v-card>
      <v-layout>
        <v-flex lg12 md12 v-if="masterData" xs12>
          <v-container grid-list-md>
            <v-layout>
              <v-flex>
                <h2>
                  {{ masterData.ags }} —
                  {{ masterData.municipality }}
                </h2>
              </v-flex>
              <v-spacer />

              <v-btn @click="cancel"> Abbrechen </v-btn>

              <modal-masterdata-diff
                :changed="masterData"
                :diff="diff"
                :original="diffOriginalCopy"
                @save="saveItem"
              />

              <master-data-view-menu
                :is-creating="false"
                :master-data="masterData"
                @removeAsSubMunicipality="
                  modalConfirmModalRemoveAsSubMunicipality = true
                "
                @resetData="resetData"
                @setAsSubMunicipality="selectMunicipalityDialog = true"
              />
            </v-layout>
          </v-container>
          <v-container v-if="validationErrors">
            <ValidationErrors :errors="validationErrors" />
          </v-container>
          <master-data-view-content
            :children="children"
            :is-creating="false"
            :master-data="masterData"
            @addNote="addNote"
          />

          <modal-set-as-submunicipality-of
            :master-data="masterData"
            @itemSelected="addParentMunicipality"
            v-model="selectMunicipalityDialog"
          />

          <ConfirmModal
            v-model="modalConfirmModalRemoveAsSubMunicipality"
            :ok-action="removeAsSubMunicipality"
            :text="`Wollen sie wirklich die Eingemeindung des Ortes ${masterData.municipality} aufheben? Diese Aktion kann nicht rückgänging gemacht werden!`"
            title="Eingemeinung aufheben"
          />
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import masterDataApi from '@/api/masterdataApi'
import MasterDataViewContent from '@/pages/masterDataView/MasterDataViewContent'
import { hasDiff } from '@/mixins/hasDiff'
import { lockRoutingWhenChanged } from '@/mixins/lockRoutingWhenChanged'
import ModalMasterdataDiff from '@/pages/masterDataView/ModalMasterdataDiff'
import MasterDataViewMenu from '@/pages/masterDataView/MasterDataViewMenu'
import ModalSetAsSubmunicipalityOf from '@/pages/masterDataView/ModalSetAsSubmunicipalityOf'
import ValidationErrors from '@/components/ValidationErrors'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ModalMasterDataView',
  components: {
    ConfirmModal,
    ValidationErrors,
    ModalSetAsSubmunicipalityOf,
    MasterDataViewMenu,
    ModalMasterdataDiff,
    MasterDataViewContent,
  },
  mixins: [hasDiff, lockRoutingWhenChanged],
  props: ['id', 'value'],
  data() {
    return {
      masterData: null,
      children: [],
      selectMunicipalityDialog: false,
      validationErrors: null,
      modalConfirmModalRemoveAsSubMunicipality: false,
    }
  },
  computed: {
    showModal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    diffObserved() {
      return this.masterData
    },
  },
  methods: {
    diffFilter(diff) {
      if (this.create) {
        return {} // do not check for diffs for new objects.
      }
      const excludedPaths = ['parent', 'parentMunicipalityAgs', 'notes']
      excludedPaths.forEach((el) => delete diff[el])

      return diff
    },
    async getData() {
      this.masterData = await masterDataApi.getEntry(this.id)
      this.setDiffObservable(this.diffObserved)
      await this.$store.dispatch(
        'setCurrentCompany',
        this.masterData.company_id
      )
      this.children = await masterDataApi.getChildrenOf(this.id)
    },
    async saveItem() {
      try {
        this.validationErrors = null
        await masterDataApi.updateEntry(this.id, this.masterData)
        this.$emit('dataSaved', this.id)
        this.$root.setSnackbar('success', `Stammdaten wurden gespeichert`)
        this.cancel()
      } catch (e) {
        this.validationErrors = e.errorObject
      }
    },

    resetData() {
      this.masterData = JSON.parse(JSON.stringify(this.diffOriginalCopy))
    },
    async addNote(text) {
      const note = await masterDataApi.addNote(this.id, text)
      this.masterData.notes.push(note)
    },
    async addParentMunicipality(formData) {
      await masterDataApi.setParentMunicipality(this.id, formData)
      await this.getData()
    },
    async removeAsSubMunicipality() {
      await masterDataApi.removeParentMunicipality(this.id)
      await this.getData()
    },
    cancel() {
      this.showModal = false
    },
  },
  watch: {
    id: {
      handler: 'getData',
      immediate: true,
    },
  },
}
</script>

<style scoped></style>
