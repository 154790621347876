import {
  formatIban,
  formatAmountToCurrency as money,
} from '@/utils/paymentUtils'
import { filterCreditEntriesWithDocumentId } from '@/utils/filters'

export function getCreditEntryEmailText(masterData, extractionInfo) {
  const documentDate = extractionInfo.fields.datum

  const years = masterData.payments
    .filter(
      (el) =>
        filterCreditEntriesWithDocumentId(el.payment_lines, extractionInfo.id)
          .length > 0
    )
    .map((paymentYear) => {
      const entries = filterCreditEntriesWithDocumentId(
        paymentYear.payment_lines,
        extractionInfo.id
      )
      const tax = entries.reduce((memo, curr) => {
        if (curr.tax.value < 0) {
          return memo + Math.abs(curr.tax.value)
        }
        return memo
      }, 0)

      const interest_additional = entries.reduce((memo, curr) => {
        if (curr.interest_additional.value < 0) {
          return memo + Math.abs(curr.interest_additional.value)
        }
        return memo
      }, 0)

      const interest_refund = entries.reduce((memo, curr) => {
        if (curr.interest_refund.value < 0) {
          return memo + Math.abs(curr.interest_refund.value)
        }
        return memo
      }, 0)

      return {
        year: paymentYear.year,
        tax,
        interest_additional,
        interest_refund,
      }
    })
    .sort((a, b) => Number(a.year) - Number(b.year))

  const interestAdditionalSum = years.reduce(
    (memo, curr) => memo + curr.interest_additional,
    0
  )
  const interestRefundSum = years.reduce(
    (memo, curr) => memo + curr.interest_refund,
    0
  )
  const interestSum = interestAdditionalSum + interestRefundSum
  const taxSum = years.reduce((memo, curr) => memo + curr.tax, 0)
  const sum = interestSum + taxSum

  const subject = `Kassenzeichen ${masterData.referenceNumber}`
  const body = `Sehr geehrte Damen und Herren,

gerne teilen wir Ihnen die Bankverbindung der ${masterData.company.name} mit:

${masterData.company.bank_name}
BIC: ${masterData.company.bank_bic}
IBAN: ${formatIban(masterData.company.bank_iban)}

Verwendungszweck: ${masterData.referenceNumber}
Betrag: ${money(sum)}

Wir bitten Sie die Erstattung der Gewerbesteuer in Höhe von ${money(
    taxSum
  )} zuzüglich Zinsen in Höhe von ${money(interestSum)} gem. Bescheid vom ${
    documentDate.value
  } zu veranlassen und keine Verrechnungen vorzunehmen.


Einzelauflistung nach Jahren:
${years.map(printYear).join('\n')}

----------

Summe: ${money(taxSum)} GewSt. + ${money(interestSum)} Zinsen
Gesamtbetrag: ${money(taxSum + interestSum)}


Für Ihr Bemühen bedanken wir uns im Voraus und stehen Ihnen für Rückfragen gern zur Verfügung.
`
  const contact = masterData.contact.find((el) => el.email)
  const recipient = contact ? contact.email : ''
  return {
    recipient,
    subject,
    body,
  }
}

function printYear(el) {
  return `- Jahr ${el.year}: ${money(el.tax)} GewSt. + ${money(
    el.interest_refund + el.interest_additional
  )} Zinsen = ${money(el.interest_refund + el.interest_additional + el.tax)}`
}
