<template>
  <v-container grid-list-md v-if="processedYears.length">
    <v-layout>
      <v-flex>
        <v-icon color="primary" style="vertical-align: bottom">info</v-icon>
        Die folgenden Jahre enthalten zu diesem Dokument Zahlungsdaten:
        <a
          @click="$emit('setYear', year)"
          class="link"
          :key="year"
          v-for="year in processedYears"
          >{{ year }}</a
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: 'ProcessedYears',
  props: {
    masterData: {
      required: true,
    },
    extractionData: {
      required: true,
    },
  },
  computed: {
    processedYears() {
      if (!this.masterData || !this.extractionData) {
        return []
      }
      const years = this.masterData.payments.filter((year) => {
        const result = year.payment_lines
          .map((line) => line.documentDates)
          .filter((documentDates) => {
            return Boolean(
              documentDates?.find(
                (doc) => doc.document_id === this.extractionData.id
              )
            )
          })
        return result.length
      })
      return years.map((el) => parseInt(el.year, 10)).sort()
    },
  },
}
</script>
<style lang="scss" scoped>
.link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  + .link {
    margin-left: 8px;
  }
}
</style>
