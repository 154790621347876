<template>
  <v-dialog lazy persistent v-model="show" width="800px">
    <notes v-bind="{ id, notes, type }" v-if="id" @close="show = false" />
  </v-dialog>
</template>

<script>
import Notes from '@/components/notes/Notes'

export default {
  name: 'AllNotesModal',
  components: { Notes },
  props: ['value', 'type', 'notes', 'id'],
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style scoped></style>
