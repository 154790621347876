<template>
  <v-layout>
    <v-flex>
      <h2>Adresse verwaltende Gemeinde</h2>
      <v-layout row>
        <v-flex
          :key="key"
          lg6
          md8
          v-for="key in ['name', 'zip', 'city', 'street']"
          xs12
        >
          <v-text-field
            :disabled="disabled"
            :label="labels['addressAdministrativeMunicipality.' + key]"
            box
            v-model="masterData.addressAdministrativeMunicipality[key]"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import fieldnames from '@/pages/masterDataView/fieldnames'

export default {
  name: 'MasterdataAdministrativeMunicipality',
  props: {
    masterData: {},
    disabled: Boolean,
  },
  computed: {
    labels() {
      return fieldnames
    },
  },
}
</script>
